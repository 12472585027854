export const DEFAULT_CONTEXT = {
  _id: 'deafult',
  name: 'Global',
};

export const DEFAULT_CONTEXT_LENGTH = 1;

export const MAX_NEW_REQUESTS_COUNT = 10;

export const INITIAL_REQUESTS = {
  requests: [
    {
      name: 'Request 1',
      related: DEFAULT_CONTEXT._id,
      profile: '',
    },
  ],
};
