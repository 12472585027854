import {
  FormOutlined,
  LeftOutlined,
  LinkOutlined,
  MailOutlined,
  MoreOutlined,
  RightOutlined,
  SendOutlined,
  ThunderboltOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Button, Collapse, Divider } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { PageTitle } from '@common/components/PageTitleWrapper';
import {
  CollapseHeader,
  ComplianceStats,
  PRIMARY_RECORDS_TABS,
  SidebarAutomations,
  SidebarEmailActivities,
  SidebarNotes,
} from '@modules/primary-records';
import { SidebarAttributes } from '@modules/primary-records/containers/SidebarAttributes';
import { getCurrentProject } from '@modules/project/selectors';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { DetailsPageLayout } from 'layouts';

import { TabButtons } from '@common/components/TabButtons';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import { PrimaryRecordStatus } from '@trustlayer/common';
import {
  usePrimaryObjectGenerality,
  usePrimaryRecordGenerality,
} from './hooks';

export const PrimaryRecordDetail = () => {
  const { objectSlug, recordId = '' } = useParams<PrimaryRecordsRoutesParams>();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[5];
  const activeTab =
    Object.values(PRIMARY_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';

  const navigate = useNavigate();

  const { primaryRecord } = usePrimaryRecordGenerality({
    recordId,
  });
  const { primaryObject } = usePrimaryObjectGenerality({
    objectSlug,
  });

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(
    activeTab === PRIMARY_RECORDS_TABS.Messages.key,
  );
  const [activeSidebarPanelKeys, setActiveSidebarPanelKeys] = useState<
    string | string[]
  >([]);

  // @ts-expect-error - useSelecter should update types
  const currentProjectId = useSelector(getCurrentProject)._id;

  const handleClose = () => {
    navigate(`/p/parties`);
  };

  return (
    <DetailsPageLayout>
      {/* TOP CONTENT */}
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions onClose={handleClose} />
        <Breadcrumb>
          <Breadcrumb.Item>
            <StyledObjectName>{objectSlug}</StyledObjectName>
          </Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      {/* MAIN CONTENT */}
      <DetailsPageLayout.MainContent>
        {primaryRecord?.status === PrimaryRecordStatus.Archived && (
          <DetailsPageLayout.ArchivedBanner>
            {`This ${primaryObject?.name} is archived`}
          </DetailsPageLayout.ArchivedBanner>
        )}
        <DetailsPageLayout.Header>
          <StyledTopLeftActions>
            {/*//! make the icon dynamic */}
            <PageTitle
              title={primaryRecord?.name}
              icon={getAntdIconOutlinedByName(primaryObject?.icon)}
            />
            <Divider type="vertical" />
            <ComplianceStats
              compliantCount={
                primaryRecord?.stats.requestRecords.compliantCount || 0
              }
              totalCount={primaryRecord?.stats.requestRecords.totalCount || 0}
            />
          </StyledTopLeftActions>

          <StyledBottomLeftActions>
            <TabButtons
              tabs={PRIMARY_RECORDS_TABS}
              activeTab={activeTab}
              onClick={(tabKey) => {
                if (tabKey === PRIMARY_RECORDS_TABS.Messages.key) {
                  setIsSidebarCollapsed(true);
                  // force sidebar open panels to close
                  setActiveSidebarPanelKeys([]);
                }

                navigate(`./${tabKey}`, {
                  relative: 'path',
                });
              }}
            />
          </StyledBottomLeftActions>

          <StyledBottomRightActions>
            <Button type="primary" icon={<SendOutlined />}>
              Send Email
            </Button>
            <Button icon={<LinkOutlined />} />
            <Button icon={<MoreOutlined />} />
          </StyledBottomRightActions>
        </DetailsPageLayout.Header>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet />
          </DetailsPageLayout.Body>
          <DetailsPageLayout.SideBar
            collapsed={isSidebarCollapsed}
            onCollapse={setIsSidebarCollapsed}
          >
            <>
              <StyledCollapseButton
                type="text"
                onClick={() => {
                  setIsSidebarCollapsed((prevValue) => !prevValue);
                  // force sidebar open panels to close
                  setActiveSidebarPanelKeys([]);
                }}
              >
                {isSidebarCollapsed ? (
                  <LeftOutlined className="leftIcon" />
                ) : (
                  <RightOutlined className="rightIcon" />
                )}
              </StyledCollapseButton>
              <Collapse
                bordered={false}
                expandIconPosition="end"
                activeKey={activeSidebarPanelKeys}
                expandIcon={isSidebarCollapsed ? () => null : undefined}
                onChange={(data) => {
                  setIsSidebarCollapsed(false);
                  setActiveSidebarPanelKeys(data);
                }}
              >
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<FormOutlined />}
                      label={isSidebarCollapsed ? '' : 'Additional Notes'}
                    />
                  }
                  key="additionalNotes"
                >
                  <SidebarNotes recordId={recordId} />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<UnorderedListOutlined />}
                      label={isSidebarCollapsed ? '' : 'Attributes'}
                    />
                  }
                  key="attributes"
                >
                  <SidebarAttributes recordId={recordId} />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<ThunderboltOutlined />}
                      label={isSidebarCollapsed ? '' : 'Automations'}
                    />
                  }
                  key="automations"
                >
                  <SidebarAutomations
                    recordId={recordId}
                    disabled={
                      primaryRecord?.status === PrimaryRecordStatus.Archived
                    }
                  />
                </Collapse.Panel>
                <Collapse.Panel
                  header={
                    <CollapseHeader
                      icon={<MailOutlined />}
                      label={isSidebarCollapsed ? '' : 'Email Activity'}
                    />
                  }
                  key="emailActivity"
                >
                  <SidebarEmailActivities recordId={recordId} />
                </Collapse.Panel>
              </Collapse>
            </>
          </DetailsPageLayout.SideBar>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledObjectName = styled.span`
  text-transform: capitalize;
  display: flex;
  gap: 5px;
  flex-direction: row;
  align-items: center;
`;

const StyledTopLeftActions = styled.div.attrs({
  className: 'top-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomLeftActions = styled.div.attrs({
  className: 'bottom-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomRightActions = styled.div.attrs({
  className: 'bottom-right-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledCollapseButton = styled(Button)`
  background-color: #fff;
  position: absolute;
  z-index: 10;
  left: -10px;
  top: 40px;
  padding: 0;
  border: 1px solid #d9d9d9;
  width: 22px;
  height: 22px;
  font-size: 13px;

  &&:hover {
    background-color: #fff;
  }

  .leftIcon {
    padding-right: 1px;
  }

  .rightIcon {
    padding-left: 1px;
  }
`;
