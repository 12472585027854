import { Select } from 'antd';
import styled from 'styled-components';

import type { KeyType } from './constants';
import type { FilterType, SelectedFilter } from './types';

type DateFilterTypeSelectProps = {
  selectedFilter?: SelectedFilter;
  filterTypes: FilterType[];
  onChange: (key: KeyType) => void;
};

const DateFilterTypeSelect = ({
  selectedFilter,
  filterTypes,
  onChange,
}: DateFilterTypeSelectProps) => {
  return (
    <div data-cy="dateFilterTypeSelect">
      <StyledSelect<KeyType>
        allowClear
        placeholder="Select..."
        defaultValue={selectedFilter?.key}
        onChange={onChange}
      >
        {filterTypes.map((option) => (
          <Select.Option key={option.key} value={option.key}>
            {option.label}
          </Select.Option>
        ))}
      </StyledSelect>
    </div>
  );
};

const StyledSelect: typeof Select = styled(Select)`
  width: 100%;
`;

export default DateFilterTypeSelect;
