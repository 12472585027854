import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

export const REPLACE_RECORD_CONTACTS_MUTATION = graphql(`
  mutation ReplaceRecordContacts($data: ReplaceRecordContactsInput!) {
    replaceRecordContacts(data: $data)
  }
`);

const REPLACE_RECORD_REFERENCE = 'Conversation';

export const useReplaceConversationParticipants = () => {
  const [replaceConversationParticipants, { loading }] = useMutation(
    REPLACE_RECORD_CONTACTS_MUTATION,
  );

  const replaceParticipants = ({
    variables,
    onCompleted,
    onError,
  }: {
    variables: { conversationId: string; contacts: string[] };
    onCompleted?: () => void;
    onError?: (error: any) => void;
  }) => {
    const { conversationId, contacts } = variables;

    const mappedContacts = contacts.map((contact) => ({
      id: contact,
    }));

    return replaceConversationParticipants({
      variables: {
        data: {
          recordContacts: [
            {
              id: conversationId,
              reference: REPLACE_RECORD_REFERENCE,
              contacts: mappedContacts,
            },
          ],
        },
      },
      onCompleted,
      onError,
    });
  };

  return {
    replaceConversationParticipants: replaceParticipants,
    loading,
  };
};
