import { isSupportedSubjectDateValues } from '../constants';

type RequirementData = {
  effectiveDate: string | null;
  expirationDate: string | null;
};

type RequirementId = string;

export type RecordRequirement = {
  subjectId: string;
};

type DocumentMetadata = {
  [x: RequirementId]: RequirementData;
};

type GetDocumentFirstExpirationDateParams = {
  metadata: DocumentMetadata | null;
  recordRequirements?: RecordRequirement[] | null;
};

const getRequirementIdsWithValidExpirationDate = (
  metadata: DocumentMetadata,
) => {
  return Object.keys(metadata).filter(
    (key) =>
      Boolean(metadata[key]) &&
      Boolean(metadata[key].expirationDate) &&
      !isSupportedSubjectDateValues(metadata[key].expirationDate),
  );
};

export const getDocumentFirstExpirationDate = ({
  metadata,
  recordRequirements,
}: GetDocumentFirstExpirationDateParams) => {
  if (!metadata) {
    return undefined;
  }

  const requirementIds = getRequirementIdsWithValidExpirationDate(metadata);

  /**
   * @note when record requirements are present it get the ones prenset in both record and document,
   * otherwise it get every document requirements.
   */
  const availableRequirmentIds = requirementIds.filter((id) => {
    return recordRequirements?.length
      ? recordRequirements.some(({ subjectId }) => subjectId === id)
      : true;
  });

  const sortedDates = availableRequirmentIds
    .map((id) => metadata[id].expirationDate)
    .sort(
      (a, b) =>
        new Date(a as string).getTime() - new Date(b as string).getTime(),
    );

  return sortedDates[0];
};
