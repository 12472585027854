import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import omit from 'lodash/omit';
import { useCallback } from 'react';
import type { Contact } from '../../../hooks';

import { DATA_MODEL_REFERENCES } from '@common/constants';

const UPDATE_MUTATION = graphql(`
  mutation UpdateContact($data: UpdateContactsInput!) {
    updateContacts(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

const mapFormContactToMutationInput = (
  contact: Contact,
  primaryRecordId: string,
) => {
  const baseContact = omit(contact, ['primary', 'defaultRequestRecipient']);

  return {
    ...baseContact,
    _id: contact._id!,
    ...((contact.primary || contact.defaultRequestRecipient) && {
      records: [
        {
          id: primaryRecordId,
          reference: DATA_MODEL_REFERENCES.primaryRecord,
          primary: Boolean(contact.primary),
          defaultRequestRecipient: Boolean(contact.defaultRequestRecipient),
        },
      ],
    }),
  };
};

export const useUpdateContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [updateContactMutation, { loading }] = useMutation(UPDATE_MUTATION);

  const updateContact = useCallback(
    ({ contact, onCompleted }: any) => {
      updateContactMutation({
        variables: {
          data: {
            contacts: [mapFormContactToMutationInput(contact, primaryRecordId)],
          },
        },
        onCompleted() {
          onCompleted?.();
        },
      });
    },
    [updateContactMutation, primaryRecordId],
  );

  return {
    loading,
    updateContact,
  };
};
