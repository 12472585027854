import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { DEFAULT_CONTEXT } from '../constants';

type Request = {
  name: string;
  profile: string;
  related?: string;
};

type CreateRequestRecordsParams = {
  requests: Request[];
  onCompleted?: () => void;
};

const CREATE_REQUEST_RECORDS_MUTATION = graphql(`
  mutation CreateRequestRecords($data: CreateRequestRecordsInput!) {
    createRequestRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

const mapClientRequestToMutationRequest = (
  requests: Request[],
  primaryRecordId: string,
) => {
  return requests.map((request) => ({
    primaryRecordId,
    name: request.name,
    ...(request.profile && { complianceProfileId: request.profile }),
    ...(request.related === DEFAULT_CONTEXT._id
      ? {}
      : { contextRecordId: request.related }),
  }));
};

export const useCreateRequestRecords = ({ recordId }: { recordId: string }) => {
  const [createRequestRecordsMutation, { loading }] = useMutation(
    CREATE_REQUEST_RECORDS_MUTATION,
  );

  const createRequestRecords = ({
    requests,
    onCompleted,
  }: CreateRequestRecordsParams) => {
    createRequestRecordsMutation({
      variables: {
        data: {
          requestRecords: mapClientRequestToMutationRequest(requests, recordId),
        },
      },
      onCompleted: () => {
        onCompleted?.();
      },
    });
  };

  return {
    createRequestRecords,
    loading,
  };
};
