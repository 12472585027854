import { KEYBOARD_KEYS, useHotkeys } from '@common/hooks';
import { Form, Modal, message } from 'antd';
import { capitalize } from 'lodash';
import { useCreatePrimaryRecordsMutation } from '../../hooks/useCreatePrimaryRecordsMutation';
import { ModalFormContent } from './container/FormModalContent';

export type CreatePrimaryRecordModalState = {
  visible: boolean;
  primaryObjectId: string;
  primaryObjectName: string;
};

type CreatePrimaryRecordModalProps = {
  modalState: CreatePrimaryRecordModalState;
  onSuccess?: () => void;
  onClose?: () => void;
};

type FormValues = {
  recordName: string | null;
  contactEmail: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactId: string | null;
};

export const CreatePrimaryRecordModal = ({
  modalState,
  onSuccess,
  onClose,
}: CreatePrimaryRecordModalProps) => {
  const [form] = Form.useForm<FormValues>();

  const { createPrimaryRecords, isCreatingPrimaryRecords } =
    useCreatePrimaryRecordsMutation();

  const handleOnClose = () => {
    form.resetFields();
    onClose?.();
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const formData = form.getFieldsValue();

      const {
        data: {
          createPrimaryRecords: { operation },
        },
      } = await createPrimaryRecords({
        variables: [
          {
            name: formData.recordName,
            primaryObjectId: modalState.primaryObjectId,
            records: [
              {
                email: formData.contactEmail,
                name: formData.contactName,
                id: formData.contactId,
                // add here the number when BE accepts it
              },
            ],
          },
        ],
      });

      if (operation.status !== 'completed') {
        message.success(
          `The creation of a ${modalState.primaryObjectName} is being processed.`,
        );
      } else {
        message.success(
          `${capitalize(modalState.primaryObjectName)} created successfully`,
        );
      }

      form.resetFields();
      onSuccess?.();
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  // Hotkeys for the modal
  useHotkeys(KEYBOARD_KEYS.ENTER, handleSubmit);

  return (
    <Modal
      title={`New ${modalState.primaryObjectName}`}
      open={modalState.visible}
      destroyOnClose
      okText={`Create ${modalState.primaryObjectName}`}
      bodyStyle={{ padding: '24px' }}
      width={800}
      okButtonProps={{
        loading: isCreatingPrimaryRecords,
        onClick: () => {
          handleSubmit();
        },
      }}
      onCancel={handleOnClose}
    >
      <ModalFormContent formInstance={form} />
    </Modal>
  );
};
