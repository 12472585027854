import { DATA_MODEL_REFERENCES } from '@common/constants';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import omit from 'lodash/omit';
import { useCallback } from 'react';
import type { Contact as FormContact } from '../../ContactForm/ContactForm';

const CREATE_CONTACT_MUTATION = graphql(`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data)
  }
`);

const mapFormContactToMutationInput = (
  contact: FormContact,
  primaryRecordId: string,
) => {
  const baseContact = omit(contact, ['primary', 'defaultRequestRecipient']);

  return {
    ...baseContact,
    ...((contact.primary || contact.defaultRequestRecipient) && {
      records: [
        {
          id: primaryRecordId,
          reference: DATA_MODEL_REFERENCES.primaryRecord,
          primary: Boolean(contact.primary),
          defaultRequestRecipient: Boolean(contact.defaultRequestRecipient),
        },
      ],
    }),
  };
};

export const useCreateContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [createContactMutation, { loading }] = useMutation(
    CREATE_CONTACT_MUTATION,
  );

  const createContact = useCallback(
    ({ contact, onCompleted }: any) => {
      createContactMutation({
        variables: {
          data: mapFormContactToMutationInput(contact, primaryRecordId),
        },
        onCompleted() {
          onCompleted?.();
        },
      });
    },
    [createContactMutation, primaryRecordId],
  );

  return {
    loading,
    createContact,
  };
};
