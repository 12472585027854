import type { QueryHookOptions } from '@apollo/client';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useQuery } from '@graphql/hooks';
import { fetchPartiesForSearch } from '@modules/party/actions';
import { getGraphqlResponse } from '@store/helpers';

import { PARTIES_FOR_SEARCH } from '../queries';

const DEFAULT_FIRST = 10;
const DEFAULT_FILTER_OPTIONS = {
  filters: {
    skip: 0,
    limit: DEFAULT_FIRST,
    name: '',
  },
};

export const usePartiesForSearch = () => {
  const [parties, setParties] = useState<any[]>([]);
  const [partiesTotalCount, setPartiesTotalCount] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const loadPartiesForSearch = useCallback(
    async (filterOptions = DEFAULT_FILTER_OPTIONS) => {
      setLoading(true);
      try {
        const res = await dispatch(
          fetchPartiesForSearch({
            filters: {
              ...filterOptions.filters,
              name: search,
            },
          }),
        );
        // @ts-ignore
        const partiesData = getGraphqlResponse(res);

        setParties(partiesData?.data || []);
        setPartiesTotalCount(partiesData?.totalCount || 0);
      } catch (err) {
        console.log('[usePartiesForSearch] error: ', err);
      }

      setLoading(false);
    },
    [dispatch, search],
  );

  const loadMorePartiesForSearch = async (
    filterOptions = DEFAULT_FILTER_OPTIONS,
  ) => {
    setLoading(true);

    const res = await dispatch(
      fetchPartiesForSearch({
        filters: {
          ...filterOptions.filters,
          skip: parties.length,
          name: search,
        },
      }),
    );

    // @ts-ignore
    const partiesData: { data: any[] } = getGraphqlResponse(res);

    setParties([...parties, ...partiesData.data]);

    setLoading(false);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const setSearchWithDebounce = useMemo(
    () => debounce(setSearch, 500),
    [setSearch],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  useEffect(() => {
    loadPartiesForSearch();
  }, [loadPartiesForSearch, search]);

  return {
    loadPartiesForSearch,
    loadMorePartiesForSearch,
    setSearch: setSearchWithDebounce,
    parties,
    partiesTotalCount,
    loading,
  };
};

export const usePartiesForSearchV2 = (
  opts?: Omit<QueryHookOptions, 'variables'>,
) => {
  const [search, setSearch] = useState('');
  const { data, loading, fetchMore } = useQuery(PARTIES_FOR_SEARCH, {
    variables: {
      filters: {
        ...DEFAULT_FILTER_OPTIONS.filters,
        name: search,
      },
    },
    ...opts,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
  const debouncedSetSearch = useMemo(
    () => debounce(setSearch, 500),
    [setSearch],
  );

  const loadMorePartiesForSearch = () => {
    fetchMore({
      variables: {
        filters: {
          ...DEFAULT_FILTER_OPTIONS.filters,
          skip: data?.listPartiesData?.data?.length || 0,
          name: search,
        },
      },
      // Merging the results in the query result to return the full list of parties
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          listPartiesData: {
            ...prev.listPartiesData,
            totalCount: fetchMoreResult.listPartiesData?.totalCount ?? 0,
            data: [
              ...(prev.listPartiesData?.data ?? []),
              ...(fetchMoreResult.listPartiesData?.data ?? []),
            ],
          },
        };
      },
    });
  };

  return {
    parties: data?.listPartiesData?.data || [],
    partiesTotalCount: data?.listPartiesData?.totalCount || 0,
    loading,
    search,
    setSearch: debouncedSetSearch,
    loadMorePartiesForSearch,
  };
};
