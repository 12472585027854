import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import { FILTER_OPERATORS } from '@common/constants/filters';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo } from 'react';
import { mapConversationsList } from '../Conversations.utils';

const DAFAULT_CONVERSATION_LIST_SIZE = 10;

const DEFAULT_MESSAGES_INPUT = {
  // get the last message of the conversation
  first: 1,
  offset: 0,
  sort: {
    createdAt: -1,
  },
};

export const CONVERSATIONS_QUERY = graphql(`
  query PrimaryRecordConversations(
    $primaryRecordInput: PrimaryRecordInput!
    $input: ConversationsInput
    $messagesInput: ConversationMessagesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      conversations(input: $input) {
        totalCount
        nodes {
          _id
          createdAt
          subject
          request
          type
          participants {
            nodes {
              _id
              contactPersonName
              email
            }
          }
          usersParticipants {
            nodes {
              _id
              email
              profile {
                name
              }
            }
          }
          messages(input: $messagesInput) {
            nodes {
              _id
              message
              createdAt
              readAt
              status
              type
              senderContact {
                _id
                email
                contactPersonName
              }
              senderUser {
                _id
                email
                profile {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`);

export const useConversations = ({ recordId }: { recordId: string }) => {
  const [fetchConversations, { loading, fetchMore, data, refetch }] =
    useLazyQuery(CONVERSATIONS_QUERY, {
      notifyOnNetworkStatusChange: true,
    });

  const {
    nodes: conversationsList = [],
    totalCount: conversationsTotalCount = 0,
  } = data?.primaryRecord.conversations || {};

  const filterConversationsByMessageOrSubject = useMemo(
    () =>
      debounce((text: string) => {
        fetchConversations({
          variables: {
            primaryRecordInput: {
              id: recordId,
            },
            messagesInput: DEFAULT_MESSAGES_INPUT,
            input: {
              offset: 0,
              first: DAFAULT_CONVERSATION_LIST_SIZE,
              filter: {
                or: [
                  {
                    name: 'message',
                    operator: FILTER_OPERATORS.contains,
                    value: text,
                  },
                  {
                    name: 'subject',
                    operator: FILTER_OPERATORS.contains,
                    value: text,
                  },
                ],
              },
            },
          },
        });
      }, 500),
    [fetchConversations, recordId],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: missing deps to trigger only during the mount time
  useEffect(() => {
    fetchConversations({
      variables: {
        primaryRecordInput: { id: recordId },
        messagesInput: DEFAULT_MESSAGES_INPUT,
        input: {
          offset: 0,
          first: DAFAULT_CONVERSATION_LIST_SIZE,
        },
      },
    });
  }, []);

  const fetchMoreConversations = useCallback(() => {
    fetchMore({
      variables: {
        primaryRecordId: recordId,
        messagesInput: DEFAULT_MESSAGES_INPUT,
        input: {
          first: DAFAULT_CONVERSATION_LIST_SIZE,
          offset: conversationsList.length,
        },
      },
    });
  }, [conversationsList.length, recordId, fetchMore]);

  const refetchConversation = useCallback(() => {
    refetch({
      primaryRecordInput: { id: recordId },
      messagesInput: DEFAULT_MESSAGES_INPUT,
      input: {
        offset: 0,
        first: DAFAULT_CONVERSATION_LIST_SIZE,
      },
    });
  }, [recordId, refetch]);

  return {
    loading,
    filterConversationsByMessageOrSubject,
    hasMoreConversationsToFetch:
      conversationsList.length < conversationsTotalCount,
    conversationsList: mapConversationsList({
      // @ts-expect-error - Server type expects optional fields as required (totalCounts, and most of the Message fields)
      conversations: conversationsList,
    }),
    fetchMoreConversations,
    refetchConversation,
  };
};
