import { WarningFilled } from '@ant-design/icons';
import styled from 'styled-components';

type RecordsTotalEmailsProps = {
  value: number;
  hasWarnings: boolean;
};

export const RecordsTotalEmails = ({
  value,
  hasWarnings,
}: RecordsTotalEmailsProps) => {
  return (
    <StyledWrapper>
      {hasWarnings && <StyledIconWarning />}
      {value}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  color: ${({ theme }) => theme.colors.black};
  display: inline-flex;
  padding: 0 8px;
  align-items: center;
  height: 30px;
  gap: 8px;
  border-radius: 8px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const StyledIconWarning = styled(WarningFilled)`
  color: ${({ theme }) => theme.colors.iconYellow};
`;
