import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { NonWaived } from '@trustlayer/common';

export const TOGGLE_REQUEST_RECORD_SUBJECT_WAIVER_OVERRIDE_MUTATION = graphql(`mutation ToggleRequestRecordSubjectWaiverOverride($data: ToggleRequestRecordSubjectsWaiverInput!) {
  toggleRequestRecordSubjectsWaiver(data: $data)
}
`);

type AddWaiverOverrideSubjectVariables = {
  variables: {
    requestId: string;
    subject: Record<string, any>;
  };
  onCompleted?: () => void;
  onError?: (error: Error) => void;
};
type RemoveWaiverOverrideSubjectVariables = {
  variables: {
    requestId: string;
    code: string;
  };
  onCompleted?: () => void;
  onError?: (error: Error) => void;
};

export function useWaiveOverrideSubject() {
  const [toggleWaiverOverrideSubject, { loading }] = useMutation(
    TOGGLE_REQUEST_RECORD_SUBJECT_WAIVER_OVERRIDE_MUTATION,
  );

  function addWaiverOverrideSubject({
    variables: { requestId, subject },
    onCompleted,
    onError,
  }: AddWaiverOverrideSubjectVariables) {
    return toggleWaiverOverrideSubject({
      variables: {
        data: {
          id: requestId!,
          subjects: [subject],
        },
      },
      onCompleted,
      onError,
    });
  }

  function removeWaiverOverrideSubject({
    variables: { requestId, code },
    onCompleted,
    onError,
  }: RemoveWaiverOverrideSubjectVariables) {
    return toggleWaiverOverrideSubject({
      variables: {
        data: {
          id: requestId!,
          subjects: [
            {
              code: code,
              status: NonWaived,
            },
          ],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    removeWaiverOverrideSubject,
    addWaiverOverrideSubject,
    loadingWaiverOverrideSubject: loading,
  };
}
