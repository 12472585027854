import { gql } from '@apollo/client';
import { FILTER_OPERATORS, FILTER_TYPES } from '@common/constants/filters';
import { useMutation } from '@graphql/hooks';

// TODO: In this mutation in the future will be added the return type for handling bulk operations
const DELETE_PRIMARY_RECORDS_MUTATION = gql`
  mutation DeletePrimaryRecords($data: DeletePrimaryRecordsInput!) {
    deletePrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type DeletePrimaryRecordsArgs = {
  variables: { ids: string[] };
};

export const useDeletePrimaryRecordsMutation = () => {
  const [deletePrimaryRecordsMutation, { loading: isDeletingPrimaryRecords }] =
    useMutation(DELETE_PRIMARY_RECORDS_MUTATION);

  const deletePrimaryRecords = ({ variables }: DeletePrimaryRecordsArgs) => {
    return deletePrimaryRecordsMutation({
      variables: {
        data: {
          filter: {
            and: [
              {
                name: FILTER_TYPES.id,
                operator: FILTER_OPERATORS.in,
                value: variables.ids,
              },
            ],
            or: [],
          },
        },
      },
    });
  };

  return {
    deletePrimaryRecords,
    isDeletingPrimaryRecords,
  };
};
