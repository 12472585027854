import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useCallback } from 'react';

type AssignContactParams = {
  contactId: string;
  onCompleted?: () => void;
};

const ASSIGN_CONTACTS_MUTATION = graphql(`
  mutation AssignContactsToPrimaryRecord($data: AssignContactsToPrimaryRecordInput!) {
    assignContactsToPrimaryRecord(data: $data)
  }
`);

export const useAssignContact = ({
  primaryRecordId,
}: { primaryRecordId: string }) => {
  const [assignContactMutation, { loading }] = useMutation(
    ASSIGN_CONTACTS_MUTATION,
  );

  const assignContact = useCallback(
    ({ contactId, onCompleted }: AssignContactParams) => {
      assignContactMutation({
        variables: {
          data: {
            primaryRecordId,
            contacts: [
              {
                contactId,
              },
            ],
          },
        },
        onCompleted() {
          onCompleted?.();
        },
      });
    },
    [assignContactMutation, primaryRecordId],
  );

  return {
    assignContact,
    loading,
  };
};
