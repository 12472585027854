import { gql } from '@apollo/client';
import { useMutation } from '@graphql/hooks';
import type { CreatePrimaryRecordsMutationVariables } from '@graphql/types/graphql';

const CREATE_PRIMARY_RECORDS_MUTATION = gql`
  mutation CreatePrimaryRecords($data: CreatePrimaryRecordsInput!) {
    createPrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type CreatePrimaryRecordsArgs = {
  variables: CreatePrimaryRecordsMutationVariables['data']['primaryRecords'];
};

export const useCreatePrimaryRecordsMutation = () => {
  const [createPrimaryRecordsMutation, { loading: isCreatingPrimaryRecords }] =
    useMutation(CREATE_PRIMARY_RECORDS_MUTATION);

  const createPrimaryRecords = ({ variables }: CreatePrimaryRecordsArgs) => {
    return createPrimaryRecordsMutation({
      variables: {
        data: {
          primaryRecords: variables,
        },
      },
    });
  };

  return {
    createPrimaryRecords,
    isCreatingPrimaryRecords,
  };
};
