import { Tooltip } from 'antd';
import DOMPurify from 'dompurify';
import { useState } from 'react';
import styled from 'styled-components';

import {
  MessageType,
  ReplyMarker,
} from '@modules/party-conversation/constants';

import { InfoMessage } from '../InfoMessage';

export const EMPTY_MESSAGE_TEXT = 'This message has no content';
export const DOCUMENT_LINK_LABEL = 'View document request page';
export const REQUEST_BASE_ROUTE = '/request-landing/';

const EVENT_WITH_DOCUMENT_REQUEST_LINK = {
  [MessageType.Request]: 'Document request was sent.',
  [MessageType.Reminder]: 'Reminder was sent.',
  [MessageType.RequestForwarded]: 'Document request forwarded.',
  [MessageType.Expiring]: 'Automatic reminder was sent.',
  [MessageType.NewTokenLink]: 'An updated access link has been requested.',
};

const EVENT_WITH_CONTACT_ACTION = {
  [MessageType.ContactRemovedFromConversation]: ({ partyContact }) =>
    `Removed ${partyContact?.email} from conversation`,
  [MessageType.ContactDeleted]: ({ partyContact }) =>
    `${partyContact?.email} deleted as contact.`,
  [MessageType.ContactEmailUpdatedResent]: ({ partyContactAfterUpdate }) =>
    `Contact changed to ${partyContactAfterUpdate?.email} and message was resent.`,
  [MessageType.MessageResent]: ({ partyContact }) =>
    `Message was resent to ${partyContact?.email}`,
};

function htmlToElement(html) {
  const template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = `<div>${html}</div>`;
  return template.content.firstChild;
}

const EventContent = ({
  type,
  outputHtml,
  conversationRequest,
  requestFromMessage,
  eventData = {},
}) => {
  const [expanded, setExpanded] = useState(false);
  const parsedHtml = htmlToElement(DOMPurify.sanitize(outputHtml));
  const marker = parsedHtml?.querySelector(`[title="${ReplyMarker}"]`);
  marker?.parentElement?.remove();

  if (type === MessageType.Message) {
    return (
      <>
        {outputHtml ? (
          <StyledMessageWrapper>
            <div
              dangerouslySetInnerHTML={{
                __html: parsedHtml.outerHTML,
              }}
            />
            {Boolean(marker?.length) && (
              <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
                <StyledExpanderButton onClick={() => setExpanded(!expanded)}>
                  •••
                </StyledExpanderButton>
              </Tooltip>
            )}
            {expanded && (
              <div
                dangerouslySetInnerHTML={{
                  __html: marker?.outerHTML,
                }}
              />
            )}
          </StyledMessageWrapper>
        ) : (
          <StyledEmptyMessage>{EMPTY_MESSAGE_TEXT}</StyledEmptyMessage>
        )}
      </>
    );
  }

  if (EVENT_WITH_DOCUMENT_REQUEST_LINK[type]) {
    return (
      <InfoMessage
        label={EVENT_WITH_DOCUMENT_REQUEST_LINK[type]}
        link={{
          href: `${REQUEST_BASE_ROUTE}${
            requestFromMessage || conversationRequest
          }`,
          label: DOCUMENT_LINK_LABEL,
        }}
      />
    );
  }

  if (EVENT_WITH_CONTACT_ACTION[type]) {
    return (
      <StyledEventLog>
        {EVENT_WITH_CONTACT_ACTION[type](eventData)}
      </StyledEventLog>
    );
  }

  return null;
};

const StyledMessageWrapper = styled.div`
  flex: 1;
  word-wrap: break-word;
  font-style: normal;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);

  p,
  ol,
  ul {
    margin-bottom: 0;
  }

  ol,
  ul {
    padding-left: 20px;
  }
`;

const StyledEmptyMessage = styled.div`
  color: ${(props) => props.theme.colors.lightGray};
  font-style: italic;
`;

const StyledExpanderButton = styled.span`
  height: 9px;
  padding: 0 5px 0 7px;
  align-items: center;
  letter-spacing: 2px;
  font-size: 10px;
  background-color: #e8edf3;
  border-radius: 10px;
  cursor: pointer;

  :hover {
    background-color: #dbdee2;
  }
`;

const StyledEventLog = styled.span`
  font-size: 13px;
  color: rgba(0, 0, 0, 0.65);
`;

export default EventContent;
