import { NetworkStatus } from '@apollo/client';
import { FILTER_OPERATORS } from '@common/constants/filters';
import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { debounce } from 'lodash';
import { useMemo } from 'react';

export type Contact = {
  _id: string;
  email?: string | null;
  contactPersonName?: string | null;
  phone?: string | null;
};

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
  offset: 0,
};

const CONTACTS_EMAIL_FOR_SEARCH_QUERY = graphql(`
  query ContactsEmailsForSearch($input: ContactsInput) {
    contacts(input: $input) {
      nodes {
        _id
        email
        contactPersonName
        phone
      }
      totalCount
    }
}
`);

export const useContactsEmailsForSearch = () => {
  const { data, loading, fetchMore, refetch, networkStatus } = useQuery(
    CONTACTS_EMAIL_FOR_SEARCH_QUERY,
    {
      variables: {
        input: DEFAULT_INPUT,
      },
      notifyOnNetworkStatusChange: true,
    },
  );

  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const isLoading = !isLoadingMore && loading;

  const filterContactsByEmail = useMemo(
    () =>
      debounce((email: string) => {
        refetch({
          input: {
            ...DEFAULT_INPUT,
            filter: {
              and: [
                {
                  name: 'email',
                  operator: FILTER_OPERATORS.contains,
                  value: email,
                },
              ],
            },
          },
        });
      }, 500),
    [refetch],
  );

  const fetchMoreContactsForSearch = () => {
    fetchMore({
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: data?.contacts.nodes.length || 0,
        },
      },
    });
  };

  return {
    contactsList: data?.contacts.nodes || [],
    contactsListTotalCount: data?.contacts.totalCount || 0,
    contactsLoading: isLoading,
    contactsLoadingMore: isLoadingMore,
    filterContactsByEmail,
    fetchMoreContactsForSearch,
  };
};
