import { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { ConversationEvent } from '@modules/party-conversation';

import { getSenderData } from './Messages.utils';
import { useMessages } from './hooks';

const MessageWrapper = ({ inView, children }: any) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inView) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [inView]);

  return <div ref={ref}>{children}</div>;
};

export const Messages = ({ conversationId, initialMessageId }: any) => {
  const { messages, messagesByDateMap } = useMessages({
    conversationId,
  });

  const inViewMessageId = initialMessageId || messages[0]?._id;

  return (
    <StyledMessagesListSection>
      {Object.keys(messagesByDateMap).map((date) => {
        const messagesList = messagesByDateMap[date];

        return (
          <StyledDateSection key={date}>
            <StyledTitle>{date}</StyledTitle>
            {messagesList.map((message: any) => (
              <MessageWrapper
                key={message._id}
                inView={message._id === inViewMessageId}
              >
                <ConversationEvent
                  conversationEvent={message}
                  senderData={getSenderData(message)}
                  conversationRequest={message.request}
                  onSaveInDocuments={() => {}}
                  onDownloadAttachment={() => {}}
                  onResolveIssue={() => {}}
                  onIgnoreIssue={() => {}}
                />
              </MessageWrapper>
            ))}
          </StyledDateSection>
        );
      })}
    </StyledMessagesListSection>
  );
};

const StyledMessagesListSection = styled.section`
  position: absolute;
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  height: 100%;
  overflow-y: scroll;
  inset: 0;

  & > section:first-child {
    margin-top: auto;
  }
`;

const StyledDateSection = styled.section`
  margin-bottom: 15px;
`;

const StyledTitle = styled.h3`
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #e6eaef;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  background-color: white;
  font-size: 12px;
`;
