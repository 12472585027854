import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const useNewBackgroundTableColorsFeatureFlag = () => {
  const { NEW_BACKGROUND_TABLE_COLORS } = FeatureFlagsMap;
  const isNewBackgroundTableColorsFeatureFlagEnabled = useFeatureFlag(
    NEW_BACKGROUND_TABLE_COLORS,
  );
  return { isNewBackgroundTableColorsFeatureFlagEnabled };
};
