import {
  KeyType,
  type SelectedFilter,
} from '../../components/DateFilterSelector';
import { FILTER_OPERATORS } from '../../constants/filters';

const DATE_OPERATOR_MAP = {
  [KeyType.LAST]: FILTER_OPERATORS.withinLast,
  [KeyType.NEXT]: FILTER_OPERATORS.withinNext,
} as const;

/**
 * @note Since we are using a legacy component (DateFilterSelector) as custom filter,
 * some keys refers to legacy filters. The following map is used to align the discrepancies.
 */
const LEGACY_TO_CURRENT_FILTER_KEYS: Record<string, string> = {
  // key in WithinDateOptions constant
  week: 'weeks',
  day: 'days',
  month: 'months',
};

const getDate = (value: number) => new Date(value).toISOString();

const getEndDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setUTCHours(23, 59, 59, 999);
  return date.toISOString();
};

const getStartDay = (timestamp: string) => {
  const date = new Date(timestamp);
  date.setUTCHours(0, 0, 0, 0);
  return date.toISOString();
};

export const getDateFilter = (param: SelectedFilter<number>) => {
  if (param.key === KeyType.ON) {
    return {
      operator: FILTER_OPERATORS.between,
      value: [
        getStartDay(getDate(param.value)),
        getEndDay(getDate(param.value)),
      ],
    };
  }
  if (param.key === KeyType.BETWEEN) {
    return {
      operator: FILTER_OPERATORS.between,
      value: [getDate(param.from), getDate(param.to)],
    };
  }

  if (param.key === KeyType.LAST || param.key === KeyType.NEXT) {
    const durationField =
      LEGACY_TO_CURRENT_FILTER_KEYS[param.withinDateType] ||
      param.withinDateType;

    return {
      operator: DATE_OPERATOR_MAP[param.key],
      value: {
        [durationField]: param.withinDateValue,
      },
    };
  }
};
