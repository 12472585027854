import {
  FolderOutlined,
  LinkOutlined,
  MoreOutlined,
  SendOutlined,
  ShopOutlined,
  SwapOutlined,
} from '@ant-design/icons';
import { PageTitle } from '@common/components/PageTitleWrapper';
import { TabButtons } from '@common/components/TabButtons';
import { REQUEST_RECORDS_TABS } from '@modules/request-records';
import { Breadcrumb, Button } from 'antd';
import { DetailsPageLayout } from 'layouts';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const RequestRecordDetail = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const tab = pathname.split('/')[7];
  const activeTab =
    Object.values(REQUEST_RECORDS_TABS).find((t) => t.key === tab)?.key ?? '';

  const handleClose = () => {
    //? Understand what we want to show if an user arrives here from a direct link
    navigate(-1);
  };

  const handleNext = () => {
    console.log('Next');
  };

  const handlePrevious = () => {
    console.log('Previous');
  };

  return (
    <DetailsPageLayout>
      <DetailsPageLayout.TopBar>
        <DetailsPageLayout.PageActions
          onClose={handleClose}
          onNext={handleNext}
          onPrevious={handlePrevious}
        />
        <Breadcrumb>
          <Breadcrumb.Item>Party Name</Breadcrumb.Item>
          <Breadcrumb.Item>N of N requests</Breadcrumb.Item>
        </Breadcrumb>
      </DetailsPageLayout.TopBar>

      <DetailsPageLayout.Header>
        <StyledTopLeftActions>
          <PageTitle.Composite
            leftTitle={
              <PageTitle
                title="Primary object name"
                icon={<ShopOutlined />}
                iconSize="small"
              />
            }
            middleIcon={<SwapOutlined />}
            rightTitle={
              <PageTitle
                title="Context Record Name"
                icon={<FolderOutlined />}
                iconSize="small"
              />
            }
          />
          <p className="requestName">Request name</p>
        </StyledTopLeftActions>

        <StyledBottomLeftActions>
          <TabButtons tabs={REQUEST_RECORDS_TABS} activeTab={activeTab} />
        </StyledBottomLeftActions>

        <StyledBottomRightActions>
          <Button type="primary" icon={<SendOutlined />}>
            Send Email
          </Button>
          <Button icon={<LinkOutlined />} />
          <Button icon={<MoreOutlined />} />
        </StyledBottomRightActions>
      </DetailsPageLayout.Header>

      <DetailsPageLayout.MainContent>
        <DetailsPageLayout.Content>
          <DetailsPageLayout.Body>
            <Outlet />
          </DetailsPageLayout.Body>
        </DetailsPageLayout.Content>
      </DetailsPageLayout.MainContent>
    </DetailsPageLayout>
  );
};

const StyledTopLeftActions = styled.div.attrs({
  className: 'top-full-actions',
})`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .requestName {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.subtitleText};
    margin: 0;
    padding: 0;
  }
`;

const StyledBottomLeftActions = styled.div.attrs({
  className: 'bottom-left-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledBottomRightActions = styled.div.attrs({
  className: 'bottom-right-actions',
})`
  display: flex;
  align-items: center;
  gap: 10px;
`;
