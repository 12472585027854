import { ApolloProvider } from '@apollo/client/react';
import * as Sentry from '@sentry/react';
import { message } from 'antd';
import { Helmet } from 'react-helmet';
import { ThemeProvider } from 'styled-components';

import PspdfkitWorkerPreloader from '@common/components/PspdfkitWorkerPreloader';
import UncaughtError from '@common/components/UncaughtError';
import client from '@graphql/client';
import { OrganizationFeatureProvider } from '@modules/add-on/containers';
import AppVersionManager from '@modules/app/containers/AppVersionManager';
import { getTheme } from '@theme/index';
import DownloadList from './modules/download/components/DownloadList';
import { FeatureFlagProvider } from './modules/feature-flags';
import Routes from './modules/router';

const { theme, GlobalStyle } = getTheme('base');

message.config({
  top: 40,
  duration: 2,
});

const App = Sentry.withProfiler(function InnerApp() {
  return (
    <Sentry.ErrorBoundary fallback={(props) => <UncaughtError {...props} />}>
      <ThemeProvider theme={theme}>
        <ApolloProvider client={client}>
          <FeatureFlagProvider>
            <OrganizationFeatureProvider>
              <Helmet
                titleTemplate="TrustLayer | %s"
                defaultTitle="TrustLayer"
              />
              <GlobalStyle />
              <PspdfkitWorkerPreloader />
              <DownloadList />
              <Routes />
              <AppVersionManager />
            </OrganizationFeatureProvider>
          </FeatureFlagProvider>
        </ApolloProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
});

export default App;
