export const FILTERABLE_COLUMN_FIELDS = {
  name: 'name',
  tags: 'tags',
  activeDocuments: 'activeDocuments',
  reviewStatus: 'reviewStatus',
  flagsSeverity: 'flagsSeverity',
  types: 'types',
  contextRecords: 'contextRecords',
  waiversStatus: 'waiversStatus',
  overridesStatus: 'overridesStatus',
  nextComplianceExpiration: 'nextComplianceExpiration',
  lastActivity: 'lastActivity',
  lastRequest: 'lastRequest',
  messageFailures: 'messageFailures',
  contactEmailStatus: 'contactEmailStatus',
} as const;

export const COLUMN_FIELDS = {
  ...FILTERABLE_COLUMN_FIELDS,
  complianceStatus: 'complianceStatus',
  totalEmails: 'totalEmails',
  actions: 'actions',
  automations: 'automations',
  createdAt: 'createdAt',
};

export const REVIEW_STATUS = {
  Reviewed: 'reviewed',
  ToReview: 'toReview',
} as const;

export const WAIVERS_STATUS = {
  WithRequirements: 'withWaivedRequirements',
  WithoutRequirements: 'withoutWaivedRequirements',
} as const;

export const OVERRIDDEN_STATUS = {
  WithOverriddenRequirements: 'withOverriddenRequirements',
  WithoutOverriddenRequirements: 'withoutOverriddenRequirements',
} as const;

export const CONTACT_EMAIL_STATUS = {
  WithContactEmail: 'withContactEmail',
  WithoutContactEmail: 'withoutContactEmail',
} as const;

export const SEVERITY_STATUS = {
  Low: 'low',
  Medium: 'medium',
  High: 'high',
} as const;
