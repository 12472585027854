import { FeatureFlagsMap } from '../constants';

import { useFeatureFlag } from './useFeatureFlag';

export const useDocumentsAgTable = () => {
  const { DOCUMENTS_AG_TABLE } = FeatureFlagsMap;

  const isDocumentsAgTableEnabled = useFeatureFlag(DOCUMENTS_AG_TABLE);

  return { isDocumentsAgTableEnabled };
};
