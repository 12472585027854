import { ClockCircleOutlined } from '@ant-design/icons';
import { Skeleton, Tag, Tooltip } from 'antd';
import moment from 'moment';
import styled from 'styled-components';

import { useQuery } from '@graphql/hooks';
import { SidebarError } from '@modules/primary-records/components/SideBarError';

import { ATTRIBUTES_QUERY } from './queries';

export const SidebarAttributes = ({ recordId }: { recordId: string }) => {
  const { data, loading, error, refetch } = useQuery(ATTRIBUTES_QUERY, {
    variables: { primaryRecordInput: { id: recordId } },
    skip: !recordId,
    notifyOnNetworkStatusChange: true,
  });

  const attributes = data?.primaryRecord.attributes?.nodes;
  const tags = data?.primaryRecord.tags?.nodes;

  if (error) {
    return (
      <SidebarError sidebarLabel="Attributes" onActionClick={() => refetch()} />
    );
  }

  /**
   * @info - in the futere tags should be exposed as a custom attribute so inside the attributes nodes
   */
  return (
    <Skeleton active loading={loading}>
      <StyledTagsWrapper>
        <p className="name">Tags</p>
        <div className="tags-wrapper">
          {tags?.map((tag) => (
            <StyledTag
              key={tag._id}
              icon={
                Boolean(tag.expiresAt) && (
                  <Tooltip
                    title={`Tag expires on ${moment(tag.expiresAt).format(
                      'll',
                    )}`}
                  >
                    <ClockCircleOutlined />
                  </Tooltip>
                )
              }
              color={tag.color || 'default'}
            >
              {tag.name}
            </StyledTag>
          ))}
        </div>
      </StyledTagsWrapper>
      <StyledAttributesWrapper>
        {attributes?.map((attribute) => (
          <StyledAttribute key={attribute._id}>
            <p className="name">{attribute.name}</p>
            <p className="value">{attribute.value}</p>
          </StyledAttribute>
        ))}
      </StyledAttributesWrapper>
    </Skeleton>
  );
};

const StyledTag = styled(Tag)`
  margin: 0;
  border-radius: 99em;
`;

const StyledTagsWrapper = styled.div`
  margin-bottom: 16px;

  .name {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayText};
  }

  .tags-wrapper {
    display: flex;
    gap: 8px;
    margin-top: 8px;
    flex-wrap: wrap;
  }
`;

const StyledAttributesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
`;

const StyledAttribute = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;

  > p {
    margin: 0;
    padding: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.grayText};
  }

  .value {
    color: ${({ theme }) => theme.colors.mineShaft};
  }
`;
