import { gql } from '@apollo/client';

import { graphql } from '@graphql/types';
import type { Branch } from '@modules/automations/types';

import type {
  PARTY_AUTOMATION_STATUS,
  SCHEDULED_ACTIONS_STATUS,
} from '../constants';

type ScheduledAction = {
  status: (typeof SCHEDULED_ACTIONS_STATUS)[keyof typeof SCHEDULED_ACTIONS_STATUS];
  scheduledAt: string;
  executedAt?: string;
  result?: Record<string, string>;
};

export type QueryPartyAutomation = {
  _id: string;
  code: string;
  name: string;
  actionsCount: number;
  branches: {
    totalCount: number;
    nodes: Branch[];
  };
  status: (typeof PARTY_AUTOMATION_STATUS)[keyof typeof PARTY_AUTOMATION_STATUS];
  processing: boolean;
  scheduledActions?: ScheduledAction[];
};

export type PartyAutomationsData = {
  getPartyDataV2: {
    automations: QueryPartyAutomation[];
  };
};

type FilterNode = {
  name: string;
  operator: string;
  value: unknown;
};

export type PartyAutomationsVariables = {
  partyId: string;
  filterInput?: {
    and: FilterNode[];
    or?: FilterNode[];
  };
};

export const PARTY_AUTOMATIONS_QUERY = gql`
  query PartyAutomations($partyId: ID!) {
    getPartyDataV2(_id: $partyId) {
      _id
      automations {
        _id
        code
        name
        actionsCount
        branches {
          nodes {
            _id
            trigger {
              event
              type
              filter
            }
            steps {
              ... on Action {
                type
                job
                data {
                  ... on EmailActionData {
                    emailTemplate {
                      _id
                    }
                  }
                }
              }
              ... on Wait {
                type
                duration
              }
            }
          }
        }
        status
        processing
        scheduledActions {
          status
          scheduledAt
          executedAt
          result
        }
      }
    }
  }
`;

export const PARTIES_FOR_SEARCH = graphql(`
  query PartiesForSearch(
    $filters: PartyListFiltersEntity
    $sort: ElementsSort
  ) {
    listPartiesData(filters: $filters, sort: $sort) {
      data {
        _id
        name
      }
      totalCount
    }
  }
`);
