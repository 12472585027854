import type { TableSortingState } from '@common/components/Table';
import {
  OVERRIDDEN_STATUS,
  WAIVERS_STATUS,
} from '@modules/primary-objects/containers/RecordsTable/constants';
import type { LegacyPartiesReportParams } from '@modules/report/types';
import { getReportDateFilter } from '@modules/report/utils';
import type { TableFiltersModel } from '../PrimaryObject';

/**
 * @name mapTableSortingToLegacyPartiesReportSorting
 * @description - Maps the sorting state to the API sorting params
 */
const mapTableSortingToLegacyPartiesReportSorting = ({
  columnId,
  direction,
}: TableSortingState) => {
  return { sortField: columnId, sortDirection: direction };
};

export const mapTableParamsToLegacyPartiesReportParams = ({
  filters,
  sorting,
}: {
  filters: TableFiltersModel;
  sorting: TableSortingState;
}): LegacyPartiesReportParams => {
  // eslint-disable-next-line fp/no-let
  let reportFilters: LegacyPartiesReportParams = {};

  if (filters?.name?.filter) {
    reportFilters.name = filters.name.filter;
  }

  if (filters?.tags?.type && filters?.tags?.filter) {
    reportFilters.tags = {
      operator: filters.tags.type,
      value: filters.tags.filter,
    };
  }

  if (filters?.activeDocuments && filters.activeDocuments?.filter) {
    if (filters?.activeDocuments?.type === 'lessThan') {
      reportFilters.documentsCount = `lessThan-${filters.activeDocuments.filter}`;
    }
    if (filters?.activeDocuments?.type === 'greaterThan') {
      reportFilters.documentsCount = `moreThan-${filters.activeDocuments.filter}`;
    }
    if (filters?.activeDocuments?.type === 'equals') {
      reportFilters.documentsCount = `exactly-${filters.activeDocuments.filter}`;
    }
  }

  if (filters.reviewStatus?.values) {
    reportFilters.reviewStatus = filters.reviewStatus
      .values as typeof reportFilters.reviewStatus;
  }

  if (filters.flagsSeverity?.values) {
    reportFilters.flag = filters.flagsSeverity
      .values as typeof reportFilters.flag;
  }

  if (filters.types) {
    reportFilters.type = filters.types.filter;
  }

  if (filters.contextRecords) {
    reportFilters.assignedToProjects = filters.contextRecords.filter;
  }

  if (filters.waiversStatus?.values) {
    reportFilters.waivers = filters.waiversStatus.values.map((value) => {
      if (value === WAIVERS_STATUS.WithRequirements) {
        return 'withWaived';
      }
      if (value === WAIVERS_STATUS.WithoutRequirements) {
        return 'withoutWaived';
      }
    }) as typeof reportFilters.waivers;
  }

  if (filters.overridesStatus?.values) {
    reportFilters.overrides = filters.overridesStatus.values.map((value) => {
      if (value === OVERRIDDEN_STATUS.WithOverriddenRequirements) {
        return 'withOverride';
      }
      if (value === OVERRIDDEN_STATUS.WithoutOverriddenRequirements) {
        return 'withoutOverride';
      }
    }) as typeof reportFilters.overrides;
  }

  if (filters.nextComplianceExpiration) {
    const expirationDateParams = getReportDateFilter(
      filters.nextComplianceExpiration,
      'nextExpirationDate',
    );
    reportFilters = {
      ...reportFilters,
      ...expirationDateParams,
    };
  }

  if (filters.lastActivity) {
    const lastActivityDateParams = getReportDateFilter(
      filters.lastActivity,
      'lastActivityDate',
    );
    reportFilters = {
      ...reportFilters,
      ...lastActivityDateParams,
    };
  }

  if (filters.lastRequest) {
    const lastRequestSentParams = getReportDateFilter(
      filters.lastRequest,
      'lastRequestSent',
    );
    reportFilters = {
      ...reportFilters,
      ...lastRequestSentParams,
    };
  }

  if (filters.messageFailures) {
    const failedMessagesParams = getReportDateFilter(
      filters.messageFailures,
      'failedMessages',
    );
    reportFilters = {
      ...reportFilters,
      ...failedMessagesParams,
    };
  }

  if (filters.contactEmailStatus?.values) {
    reportFilters.contactEmail = filters.contactEmailStatus
      .values as typeof reportFilters.contactEmail;
  }

  const reportSorting = mapTableSortingToLegacyPartiesReportSorting(sorting);

  return {
    ...reportFilters,
    ...reportSorting,
  };
};
