import { type ActionType, PRIMARY_RECORDS_ACTION_TYPES } from '../constants';

type Params = {
  excludedActions?: ActionType[];
  options?: Record<string, boolean>;
};

export const getActionsMenuItems = ({
  excludedActions = [],
  options,
}: Params) => {
  const actions = [
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
      label: 'Enable automation',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.disableAutomation,
      label: 'Disable automation',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.archive,
      label: 'Archive',
    },
    {
      code: PRIMARY_RECORDS_ACTION_TYPES.delete,
      label: 'Delete',
    },
  ];

  return actions.filter(
    (action) => excludedActions.includes(action.code) === false,
  );
};
