import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { useState } from 'react';

const UPDATE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION = graphql(`
  mutation updateRequestRecordsRequirementPublicNotes($data: [UpdateRequestRecordRequirementPublicNotesInput!]!) {
    updateRequestRecordsRequirementPublicNotes(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`);

export const usePublicNote = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [isDeletingList, setIsDeletingList] = useState<string[]>([]);

  const [updatePublicNote, { error }] = useMutation(
    UPDATE_REQUEST_RECORD_REQUIREMENT_PUBLIC_NOTES_MUTATION,
  );

  const deletePublicNote = async ({
    variables: { attributeCode, id },
    onCompleted,
    onError,
  }: {
    variables: {
      attributeCode: string;
      id: string;
    };
    onCompleted: () => void;
    onError: (error: Error) => void;
  }) => {
    setIsDeletingList([...isDeletingList, attributeCode]);
    return await updatePublicNote({
      variables: {
        data: [
          {
            attributeCode,
            id,
            publicNotes: null,
          },
        ],
      },
      onCompleted: () => {
        setIsDeletingList(
          isDeletingList.filter((code) => code !== attributeCode),
        );
        onCompleted();
      },
      onError: (error) => {
        setIsDeletingList(
          isDeletingList.filter((code) => code !== attributeCode),
        );
        onError(error);
      },
    });
  };

  const createPublicNote = async ({
    variables: { attributeCode, id, publicNotes },
    onCompleted,
    onError,
  }: {
    variables: {
      attributeCode: string;
      id: string;
      publicNotes: string;
    };
    onCompleted: () => void;
    onError: (error: Error) => void;
  }) => {
    setIsCreating(true);
    return await updatePublicNote({
      variables: {
        data: [
          {
            attributeCode,
            id,
            publicNotes,
          },
        ],
      },
      onCompleted: () => {
        setIsCreating(false);
        onCompleted();
      },
      onError: (error) => {
        setIsCreating(false);
        onError(error);
      },
    });
  };

  return {
    deletePublicNote,
    createPublicNote,
    isCreatingNote: isCreating,
    isDeletingNoteList: isDeletingList,
    error,
  };
};
