import { isSupportedSubjectDateValues } from '@modules/document/constants';
import { ExpiringIcon } from '@trustlayer/ui';
import { Popover } from 'antd';
import { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { BadgeSize } from '../model/badges';
import {
  getEffectiveStatus,
  getExpirationStatus,
} from '../utils/badges-helpers';

const ExpirationBadgePopover = ({
  defaultVisible,
  isDefaultTooltip,
  effectiveDate,
  expirationDate,
  sizeIcon,
}) => {
  const themeContext = useContext(ThemeContext);

  if (
    isSupportedSubjectDateValues(effectiveDate) ||
    isSupportedSubjectDateValues(expirationDate)
  ) {
    return null;
  }

  const date = Boolean(effectiveDate)
    ? getEffectiveStatus(effectiveDate)
    : getExpirationStatus(expirationDate);

  const renderDefaultTooltipIcon = () => (
    <ExpirationBadgePopover.IconContent>
      <ExpiringIcon
        clockColor={themeContext.colors[date.color]}
        width={BadgeSize[sizeIcon]}
        height={BadgeSize[sizeIcon]}
      />
    </ExpirationBadgePopover.IconContent>
  );

  const renderTooltipIcon = () => (
    <ExpirationBadgePopover.IconContent>
      <ExpiringIcon
        clockColor={themeContext.colors[date.color]}
        width={BadgeSize[sizeIcon]}
        height={BadgeSize[sizeIcon]}
      />
    </ExpirationBadgePopover.IconContent>
  );

  if (isDefaultTooltip) {
    return Boolean(date.status) ? (
      <Popover content={<span>{date.status}</span>}>
        {renderDefaultTooltipIcon()}
      </Popover>
    ) : (
      <> {renderDefaultTooltipIcon()}</>
    );
  }

  if (!defaultVisible && !Boolean(expirationDate)) {
    return null;
  }

  return (
    <ExpirationBadgePopover.Clock>
      {Boolean(date.status) ? (
        <Popover
          placement="bottom"
          overlayStyle={{ maxWidth: '300px' }}
          content={
            <ExpirationBadgePopover.Wrapper>
              <ExpirationBadgePopover.IconTooltip>
                <ExpiringIcon
                  clockColor={themeContext.colors[date.color]}
                  width={BadgeSize.Medium}
                  height={BadgeSize.Medium}
                />
              </ExpirationBadgePopover.IconTooltip>
              <ExpirationBadgePopover.Description>
                <p>{date.status}</p>
              </ExpirationBadgePopover.Description>
            </ExpirationBadgePopover.Wrapper>
          }
        >
          {renderTooltipIcon()}
        </Popover>
      ) : (
        <>{renderTooltipIcon()}</>
      )}
    </ExpirationBadgePopover.Clock>
  );
};

ExpirationBadgePopover.Clock = styled.div`
  display: flex;
  margin-right: 7px;
`;

ExpirationBadgePopover.Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

ExpirationBadgePopover.IconContent = styled.div`
  display: flex;
`;

ExpirationBadgePopover.IconTooltip = styled.div`
  display: flex;
  margin-top: 4px;
`;

ExpirationBadgePopover.Description = styled.div`
  color: ${(props) => props.theme.colors.grayishBlue};
  font-size: 13px;
  margin: 0;
  padding: 0 0 0 15px;

  & > span {
    display: block;
    font-weight: 300;
  }

  p {
    margin: 0;
    padding: 0;
  }
`;

ExpirationBadgePopover.defaultProps = {
  isDefaultTooltip: false,
  defaultVisible: false,
  sizeIcon: 'Medium',
};

export default ExpirationBadgePopover;
