import { EditOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';

import SearchInput from '@common/components/SearchInput';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';
import {
  EmptyConversationState,
  MessagesIssuesQuickView,
  PartyConversationType,
} from '@modules/party-conversation';

import { InifiniteScrollList } from '@common/components/InfiniteScrollList';
import type { PropType } from '@common/types';
import { ConversationHeader } from './components/ConversationHeader';
import { ConversationPreview } from './components/ConversationPreview';
import { MessageEditor } from './components/MessageEditor/MessageEditor';
import { AddParticipantsPopover } from './containers/AddParticipantsPopover';
import { Messages } from './containers/Messages';
import { useConversations, useReplaceConversationParticipants } from './hooks';
import { useSendMessage } from './hooks/useSendMessage';

type OnChangeParams = {
  conversationId: string;
  messageId?: string;
};

type ConversationsProps = {
  recordId: string;
  initialConversation?: {
    id: string;
    messageId?: string;
  };
  onChange?: (params: OnChangeParams) => void;
};

export const Conversations = ({
  recordId,
  initialConversation,
  onChange,
}: ConversationsProps) => {
  const [isEditParticipantsModalVisible, setIsEditParticipantsModalVisible] =
    useState(false);

  const {
    conversationsList,
    filterConversationsByMessageOrSubject,
    loading,
    fetchMoreConversations,
    hasMoreConversationsToFetch,
    refetchConversation,
  } = useConversations({ recordId });

  const [activeConversationId, setActiveConversationId] = useState<string>(
    initialConversation?.id || conversationsList[0]?._id,
  );

  const {
    replaceConversationParticipants,
    loading: loadingReplaceParticipants,
  } = useReplaceConversationParticipants();

  const { sendConversationMessage, loading: loadingSendMessage } =
    useSendMessage(activeConversationId);

  const currentConversation = conversationsList.find(
    (conversation) => conversation._id === activeConversationId,
  );

  const handleConversationClick = (conversationId: string) => {
    setActiveConversationId(conversationId);
    onChange?.({ conversationId });
  };

  const handleUpdateParticipants = (data: string[]) => {
    replaceConversationParticipants({
      variables: {
        conversationId: currentConversation!._id,
        contacts: data,
      },
      onCompleted: () => {
        refetchConversation();
        setIsEditParticipantsModalVisible(false);
      },
      onError: () => {
        message.error('Error while updating participants, please try again.');
        setIsEditParticipantsModalVisible(false);
      },
    });
  };

  const handleSendMessage: PropType<typeof MessageEditor, 'onOk'> = (
    conversationMessage,
  ) => {
    sendConversationMessage({
      variables: {
        text: conversationMessage.text,
        htmlString: conversationMessage.html,
        attachments: conversationMessage.attachments,
      },
      onCompleted: refetchConversation,
      onError: () => {
        message.error('Error while sending message');
      },
    });
  };

  const currentConversationParticipants = [
    ...(currentConversation?.usersParticipants || []),
    ...(currentConversation?.contactsParticipants || []),
  ];

  return (
    <StyledConversations>
      <StyledSearch>
        <SearchInput
          placeholder="Search email..."
          onSearch={(text: string) => {
            filterConversationsByMessageOrSubject(text);
          }}
        />
      </StyledSearch>
      <StyledConversationHeader>
        {currentConversation && (
          <ConversationHeader
            subject={currentConversation.subject || ''}
            participants={currentConversationParticipants}
            contactsEditor={
              <HideForViewerRole>
                <AddParticipantsPopover
                  isPopoverVisible={isEditParticipantsModalVisible}
                  isPopoverLoading={loadingReplaceParticipants}
                  primaryRecordId={recordId}
                  currentParticipants={currentConversation.contactsParticipants}
                  onClose={() => {
                    setIsEditParticipantsModalVisible(false);
                  }}
                  onApplyUpdates={(data) => {
                    handleUpdateParticipants(data);
                  }}
                >
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => {
                      setIsEditParticipantsModalVisible(true);
                    }}
                  >
                    edit contacts
                  </Button>
                </AddParticipantsPopover>
              </HideForViewerRole>
            }
          >
            <>
              <MessagesIssuesQuickView
                conversationId={currentConversation._id}
                onIssueClick={(data: any) => {
                  console.log('issue clicked', data);
                }}
              />
              <HideForViewerRole>
                {currentConversation.type ===
                  PartyConversationType.DocumentRequest && (
                  <Button
                    target="_blank"
                    data-cy="documentsRequestPageBtn"
                    rel="noopener noreferrer"
                    href={`/request-landing/${currentConversation.request}`}
                  >
                    Document request page
                  </Button>
                )}
              </HideForViewerRole>
            </>
          </ConversationHeader>
        )}
      </StyledConversationHeader>
      <StyledConversationsList>
        <InifiniteScrollList
          list={conversationsList}
          loading={loading}
          hasMoreItems={hasMoreConversationsToFetch}
          loadMoreItems={fetchMoreConversations}
        >
          {(conversation) => {
            const lastConversationMessage = conversation.lastMessage;
            return (
              <StyledItem
                key={conversation._id}
                onClick={() => {
                  handleConversationClick(conversation._id);
                }}
              >
                <ConversationPreview
                  isActive={activeConversationId === conversation._id}
                  type={conversation.type}
                  subject={conversation.subject || ''}
                  contacts={currentConversationParticipants.map(
                    ({ name, email }) => name || email,
                  )}
                  messageText={lastConversationMessage.text}
                  isMessageRead={Boolean(lastConversationMessage.isRead)}
                  messageStatus={lastConversationMessage.status}
                  messageDate={lastConversationMessage.createdAt}
                  messageAuthor={lastConversationMessage.author}
                />
              </StyledItem>
            );
          }}
        </InifiniteScrollList>
      </StyledConversationsList>
      <StyledMessages>
        {/* TODO: handle messages loading */}
        {currentConversation ? (
          <Messages
            conversationId={currentConversation._id}
            initialMessageId={initialConversation?.messageId}
            documentRequestId={currentConversation?.request}
          />
        ) : (
          <EmptyConversationState
            // @todo - add valid props
            isEmptyConversations={false}
            isEmptyContacts={false}
            onClick={() => {
              console.log('Create new conversation');
            }}
          />
        )}
      </StyledMessages>
      <HideForViewerRole>
        <StyledTextEditor>
          <MessageEditor
            isSendingMessage={loadingSendMessage}
            onOk={handleSendMessage}
          />
        </StyledTextEditor>
      </HideForViewerRole>
    </StyledConversations>
  );
};

const StyledConversations = styled.section`
  --line-tichness: 1px;
  --line-color: ${({ theme }) => theme.colors.lightGray};

  height: 100%;
  max-height: 726px;
  display: grid;
  border: 1px solid var(--line-color);
  border-radius: 8px;
  grid-template-columns: 342px 1fr;
  grid-template-rows: 64px 1fr 145px;
  gap: var(--line-tichness);
  background-color: var(--line-color);
  overflow: hidden;
  grid-template-areas:
    'search messageHeader'
    'messagesList message'
    'messagesList textEditor';
`;

const StyledSearch = styled.section`
  grid-area: search;
  display: flex;
  align-items: center;
  padding: 16px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledConversationHeader = styled.section`
  grid-area: messageHeader;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledConversationsList = styled.section`
  grid-area: messagesList;
  background-color: ${({ theme }) => theme.colors.white};
  height: 659px;
  overflow: hidden;
`;

const StyledMessages = styled.section`
  grid-area: message;
  position: relative;
  height: 513px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledTextEditor = styled.section`
  grid-area: textEditor;
  padding: 16px;
  max-height: 145px;
  background-color: ${({ theme }) => theme.colors.white};
`;

const StyledItem = styled.li`
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGray};
`;
