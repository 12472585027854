import { gql } from '@apollo/client';

import { namespace } from '../constants';

export const FETCH_PARTIES_FOR_SEARCH = `${namespace}/FETCH_PARTIES_FOR_SEARCH`;

const FETCH_PARTIES_FOR_SEARCH_QUERY = gql`
  query listPartiesData($filters: PartyListFiltersEntity, $sort: ElementsSort) {
    listPartiesData(filters: $filters, sort: $sort) {
      data {
        _id
        name
      }
      totalCount
    }
  }
`;

export const fetchPartiesForSearch = (payload) => ({
  type: FETCH_PARTIES_FOR_SEARCH,
  payload: {
    key: 'listPartiesData',
    graphql: {
      fetchPolicy: 'no-cache',
      query: FETCH_PARTIES_FOR_SEARCH_QUERY,
      variables: payload,
    },
  },
});
