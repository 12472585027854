export function objectsMerge() {
  return {
    merge(existing: Record<string, any>, incoming: Record<string, any>) {
      return {
        ...existing,
        ...incoming,
      };
    },
  };
}
