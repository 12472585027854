import { recordEmptyUndefinedToNull } from '@trustlayer/common';
import { Form } from 'antd';
import Modal from 'antd/es/modal/Modal';
import { ContactForm } from '../ContactForm';
import { useCreateContact } from './hooks';

type CreateContactModalProps = {
  primaryRecordId: string;
  emailValue: string;
  onClose: () => void;
  onCompleted: () => void;
};

export const CreateContactModal = ({
  primaryRecordId,
  emailValue,
  onClose,
  onCompleted,
}: CreateContactModalProps) => {
  const { createContact, loading } = useCreateContact({ primaryRecordId });
  const [form] = Form.useForm();

  const handleCreateContact = async () => {
    const isFormValid = await form.validateFields();

    if (!isFormValid) {
      return;
    }

    const newContact = form.getFieldsValue();

    createContact({
      contact: recordEmptyUndefinedToNull(newContact),
      onCompleted: onCompleted,
    });
  };

  return (
    <Modal
      open
      title="Create Contact"
      okText="Create contact"
      onOk={handleCreateContact}
      confirmLoading={loading}
      onCancel={() => {
        onClose?.();
      }}
    >
      <ContactForm
        initialValue={{
          email: emailValue,
        }}
        form={form}
      />
    </Modal>
  );
};
