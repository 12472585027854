import { DATA_MODEL_REFERENCES } from '@common/constants';
import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

const REMOVE_RECORD_CONTACTS = graphql(`
  mutation RemoveRecordContacts($data: RemoveRecordContactsInput!) {
    removeRecordContacts(data: $data)
  }
`);

export const useDeleteContact = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const [deleteContact, { loading }] = useMutation(REMOVE_RECORD_CONTACTS);

  const handleDeleteContact = (
    contactId: string,
    {
      onCompleted,
      onError,
    }: { onCompleted?: () => void; onError?: () => void },
  ) => {
    deleteContact({
      variables: {
        data: {
          recordContacts: [
            {
              id: primaryRecordId,
              contacts: [contactId],
              reference: DATA_MODEL_REFERENCES.primaryRecord,
            },
          ],
        },
      },
      onCompleted() {
        onCompleted?.();
      },
      onError() {
        onError?.();
      },
    });
  };

  return {
    deleteContact: handleDeleteContact,
    loading,
  };
};
