import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { ContextRecordsQuery } from '@graphql/types/graphql';

import { DEFAULT_CONTEXT } from '../constants';

type ContextRecord = {
  _id: string;
  name: string;
};

const DAFAULT_PAGE_SIZE = 10;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
};

const CONTEXT_RECORDS_QUERY = graphql(`
  query ContextRecords($input: ContextRecordsInput) {
    contextRecords(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

export const useContextRecords = () => {
  const [contextRecords, setContextRecords] = useState<ContextRecord[]>([]);
  const [contextRecordsTotalCount, setContextRecordsTotalCount] = useState(0);

  const [fetchContextRecords, { loading, fetchMore }] = useLazyQuery(
    CONTEXT_RECORDS_QUERY,
    {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
    },
  );

  const onCompleted = ({ contextRecords }: ContextRecordsQuery) => {
    setContextRecords(contextRecords.nodes);
    setContextRecordsTotalCount(contextRecords.totalCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const filterContextRecordsByName = useMemo(
    () =>
      debounce((name: string) => {
        fetchContextRecords({
          variables: {
            input: {
              ...DEFAULT_INPUT,
              offset: 0,
              filter: {
                and: [
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: name,
                  },
                ],
              },
            },
          },
          onCompleted,
        });
      }, 500),
    [fetchContextRecords],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    fetchContextRecords({
      onCompleted,
    });
  }, [fetchContextRecords]);

  return {
    contextRecords: [DEFAULT_CONTEXT, ...contextRecords],
    contextRecordsTotalCount,
    loading,
    filterContextRecordsByName,
    fetchMoreContextRecords: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: contextRecords.length,
          },
        },
      });
    },
  };
};
