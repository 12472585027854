/**
 * @link - https://github.com/apollographql/apollo-client/blob/main/src/utilities/policies/pagination.ts#L33-L49
 * @link - https://www.apollographql.com/docs/react/pagination/offset-based/#the-offsetlimitpagination-helper
 * @description This function is used to merge the incoming data with the existing data in an additive manner.
 */

function offsetLimitPagination() {
  return {
    keyArgs: ['input', ['filter', 'sort']],
    merge(
      existing: { nodes: any[] },
      incoming: { nodes: any[] },
      { args }: { args: { input: { offset: number } } },
    ) {
      const { offset = 0 } = args.input || {};

      if (!offset) {
        return incoming;
      }

      const mergedNodes = [
        ...(existing?.nodes || []),
        ...(incoming?.nodes || []),
      ];

      return {
        ...(existing || {}),
        ...(incoming || {}),
        nodes: mergedNodes,
      };
    },
  };
}

export default offsetLimitPagination;
