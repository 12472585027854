import { EditOutlined } from '@ant-design/icons';
import { Button, Card, Skeleton } from 'antd';
import styled from 'styled-components';

import { ThreeDotsMenu } from '@common/components/ThreeDotsMenu';
import { useComplianceProfileEditor } from '@modules/feature-flags/hooks';
import { HideForViewerRole } from '@modules/organization-member';
import type { PropsWithChildren } from 'react';

export enum ActionKeys {
  makeCompliant = 'makeCompliant',
  edit = 'edit',
  assign = 'assign',
}

type ComplianceSummaryProps = PropsWithChildren<{
  name?: string;
  hasProfile: boolean;
  loading?: boolean;
  onActionClick: (key: ActionKeys) => void;
}>;

const DEFAULT_NAME = 'Custom Compliance Profile';
const ACTIONS = [
  {
    label: 'Make Compliant',
    code: ActionKeys.makeCompliant,
  },
];

const EmptyStateContent = ({
  onAssignClick,
}: { onAssignClick: () => void }) => {
  return (
    <section>
      <StyledSecondaryTitle>
        Assign a compliance profile to track requirements and expirations.
      </StyledSecondaryTitle>
      <Button onClick={onAssignClick}>Assign profile</Button>
    </section>
  );
};

export const ComplianceSummary = ({
  name = DEFAULT_NAME,
  loading = false,
  onActionClick,
  hasProfile,
  children,
}: ComplianceSummaryProps) => {
  const { isComplianceProfileEditorFeatureFlagEnabled } =
    useComplianceProfileEditor();

  const content = hasProfile ? (
    <StyledContent>
      <StyledComplianceProfileWrapper>
        <StyledNameWrapper>
          <span className="prefix">PROFILE:</span>
          <h3 className="title">{name}</h3>
        </StyledNameWrapper>
        {isComplianceProfileEditorFeatureFlagEnabled && (
          <HideForViewerRole>
            <StyledEditButton
              shape="circle"
              type="link"
              onClick={() => {
                onActionClick(ActionKeys.edit);
              }}
            >
              <EditOutlined />
            </StyledEditButton>
          </HideForViewerRole>
        )}
      </StyledComplianceProfileWrapper>
      {children}
    </StyledContent>
  ) : (
    <EmptyStateContent
      onAssignClick={() => {
        onActionClick(ActionKeys.assign);
      }}
    />
  );

  return (
    <Card
      bordered
      title="Compliance summary"
      extra={
        <ThreeDotsMenu
          items={ACTIONS}
          onClick={(key) => {
            onActionClick(key as ActionKeys);
          }}
        />
      }
    >
      {loading ? <StyledSkeleton active /> : content}
    </Card>
  );
};

const StyledComplianceProfileWrapper = styled.div`
  flex: 1;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(195, 209, 226, 0.8);
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.blueGray};
`;

const StyledContent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

const StyledNameWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .prefix {
    color: ${({ theme }) => theme.colors.lightTextGray};
  }

  .title {
    margin: 0;
  }
`;

const StyledSkeleton = styled(Skeleton.Input)`
  width: 100% !important;

  &&.ant-skeleton-input {
    width: 100%;
  }
`;

const StyledSecondaryTitle = styled.p`
  margin: 0;
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.subtitleText}
`;

const StyledEditButton = styled(Button)`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.paleGray};
  padding: 0 5px;
`;
