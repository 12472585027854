import styled from 'styled-components';

import { Compliance } from '@common/icons';
import { Space } from 'antd';
import { getComplianceStatusFromValue } from './RecordsCompliance.utils';

type RecordsComplianceProps = {
  compliants: number;
  total: number;
  showLabel?: boolean;
};

export const RecordsCompliance = ({
  total,
  compliants,
  showLabel,
}: RecordsComplianceProps) => {
  const status = getComplianceStatusFromValue(compliants, total);

  return (
    <StyledWrapper size="small">
      <StyledCompliance type={status} />
      <div>
        {compliants}/{total}
        {showLabel && ' Compliant'}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled(Space)`
  .ant-space-item {
    display: inline-flex;
    align-items: center;
  }
`;

const StyledCompliance = styled(Compliance)`
  width: 20px;
  height: 20px;
`;
