import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { ContactsListQuery } from '@graphql/types/graphql';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

export type Contact = {
  _id: string;
  email?: string | null;
  contactPersonName?: string | null;
};

const DAFAULT_PAGE_SIZE = 50;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
};

const CONTACTS_EMAIL_QUERY = graphql(`
  query ContactsList($input: ContactsInput) {
    contacts(input: $input) {
      nodes {
        _id
        email
        contactPersonName
      }
      totalCount
    }
}
`);

export const useContactsEmails = () => {
  const [contactsList, setContactsList] = useState<Contact[]>([]);
  const [contactsListTotalCount, setContactsListTotalCount] = useState(0);

  const [fetchContextRecords, { loading, fetchMore }] = useLazyQuery(
    CONTACTS_EMAIL_QUERY,
    {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
    },
  );

  const onCompleted = ({ contacts }: ContactsListQuery) => {
    setContactsList(contacts.nodes);
    setContactsListTotalCount(contacts.totalCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const filterContactsByEmail = useMemo(
    () =>
      debounce((email: string) => {
        fetchContextRecords({
          variables: {
            input: {
              ...DEFAULT_INPUT,
              offset: 0,
              filter: {
                and: [
                  {
                    name: 'email',
                    operator: FILTER_OPERATORS.contains,
                    value: email,
                  },
                ],
              },
            },
          },
          onCompleted,
        });
      }, 500),
    [fetchContextRecords],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    fetchContextRecords({
      onCompleted,
    });
  }, [fetchContextRecords]);

  return {
    contactsList,
    contactsListTotalCount,
    loading,
    filterContactsByEmail,
    fetchMoreContacts: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: contactsList.length,
          },
        },
      });
    },
  };
};
