import { ExclamationCircleFilled } from '@ant-design/icons';

import { CollapseCard } from '@common/components/CollapseCard';
import Flex from '@common/components/Flex';
import FullWidthSpace from '@common/components/FullWidthSpace';
import useSetState from '@common/hooks/useSetState';
import { useQuery } from '@graphql/hooks';
import type {
  ComplianceModule,
  ComplianceRequirement,
  ComplianceSubject,
} from '@graphql/types/graphql';
import {
  ACTION_TYPES,
  CircleProgress,
  ComplianceSummary,
  ActionKeys as ComplianceSummaryActionKeys,
  Requirement,
  Subject,
} from '@modules/request-records';
import type { RequestRecordsRoutesParams } from '@modules/router/types';
import { RequirementComplianceStatus } from '@trustlayer/common';
import {
  Card,
  Drawer,
  Empty,
  Modal,
  Result,
  Row,
  Space,
  Typography,
  message,
} from 'antd';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NoteModal } from './components/NoteModal';
import { ComplianceEditor } from './containers/ComplianceEditor';
import {
  ActionType as ModalActionType,
  ItemType as ModalItemType,
  WaiveAndOverrideModal,
} from './containers/WaiveAndOverrideModal';
import type { SubmitParams } from './containers/WaiveAndOverrideModal/WaiveAndOverrideModal';
import {
  getActionKey,
  getWaiveOverrideMutationPayload,
} from './containers/WaiveAndOverrideModal/WaiveAndOverrideModal.utils';
import { usePublicNote } from './hooks/usePublicNote';
import { useWaiveOverrideRequirement } from './hooks/useWaiveOverrideRequirement';
import { useWaiveOverrideSubject } from './hooks/useWaiveOverrideSubject';
import { REQUEST_RECORD_COMPLIANCE_QUERY } from './queries';

type SubjectData = {
  code: ComplianceSubject['code'];
  label: string;
  status: ComplianceSubject['status'];
  notes?: ComplianceSubject['notes'];
  resetOn?: ComplianceSubject['resetOn'];
  publicNote?: ComplianceRequirement['publicNotes'];
};

type RequirementData = {
  code: ComplianceRequirement['attributeCode'];
  label: string;
  publicNote?: ComplianceRequirement['publicNotes'];
  status: ComplianceRequirement['status'];
  notes?: ComplianceRequirement['notes'];
  resetOn?: ComplianceRequirement['resetOn'];
};

type WaiveAndOverrideModalState =
  | {
      visible: false;
    }
  | {
      visible: true;
      actionType: ModalActionType;
      itemType: ModalItemType;
      itemData: SubjectData | RequirementData;
    };

type NoteModalState =
  | {
      visible: false;
      isSubmitting?: boolean;
    }
  | {
      visible: true;
      itemData: SubjectData | RequirementData;
      isSubmitting: boolean;
      isEdit: boolean;
    };

type RemoveWaiverOverrideModalState =
  | {
      visible: false;
    }
  | {
      visible: true;
      actionType: ModalActionType;
      itemType: ModalItemType;
      itemData: {
        code: SubjectData['code'] | RequirementData['code'];
      };
    };

export function RequestRecordCompliance() {
  const [noteModalState, setNoteModalState] = useSetState<NoteModalState>({
    visible: false,
  });

  const [isComplianceEditorVisible, setIsComplianceEditorVisible] =
    useState(false);
  const [waiveAndOverrideModalState, setWaiveAndOverrideModalState] =
    useSetState<WaiveAndOverrideModalState>({
      visible: false,
    });
  const [removeWaiverOverrideModalState, setRemoveWaiverOverrideModalState] =
    useState<RemoveWaiverOverrideModalState>({
      visible: false,
    });

  const { requestId } = useParams<RequestRecordsRoutesParams>();
  const { data, loading, error, refetch } = useQuery(
    REQUEST_RECORD_COMPLIANCE_QUERY,
    {
      variables: {
        requestRecordInput: { id: requestId },
      },
      skip: !requestId,
    },
  );
  const { createPublicNote, deletePublicNote, isDeletingNoteList } =
    usePublicNote();
  const complianceProfile = data?.requestRecord?.complianceProfile;
  const complianceStats = data?.requestRecord?.complianceStats;
  const complianceModules = (
    data?.requestRecord?.complianceModules ?? []
  ).filter((m) => m) as ComplianceModule[];

  const {
    addWaiverOverrideRequirement,
    removeWaiverOverrideRequirement,
    loadingWaiverOverrideRequirement,
  } = useWaiveOverrideRequirement();
  const {
    addWaiverOverrideSubject,
    removeWaiverOverrideSubject,
    loadingWaiverOverrideSubject,
  } = useWaiveOverrideSubject();

  const handleRequirementActionClick = ({
    actionKey,
    requirementData,
  }: { actionKey: ACTION_TYPES; requirementData: RequirementData }) => {
    if (actionKey === ACTION_TYPES.addNote) {
      setNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: false,
      });
    }

    if (actionKey === ACTION_TYPES.editNote) {
      setNoteModalState({
        visible: true,
        itemData: requirementData,
        isEdit: true,
      });
    }

    if (
      actionKey === ACTION_TYPES.waive ||
      actionKey === ACTION_TYPES.override ||
      actionKey === ACTION_TYPES.edit
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getActionKey(actionKey, requirementData.status),
        itemType: ModalItemType.requirement,
        itemData: requirementData,
      });
    }

    if (actionKey === ACTION_TYPES.remove) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          requirementData.status === RequirementComplianceStatus.Waived
            ? ModalActionType.waive
            : ModalActionType.override,
        itemType: ModalItemType.requirement,
        itemData: {
          code: requirementData.code,
        },
      });
    }
  };
  const handleCloseWaiveOverrideModal = () => {
    setWaiveAndOverrideModalState({ visible: false });
  };
  const handleCloseRemoveWaiveOverrideModal = () => {
    setRemoveWaiverOverrideModalState({ visible: false });
  };
  const handleSubjectActionClick = ({
    actionKey,
    subjectData,
  }: { actionKey: ACTION_TYPES; subjectData: SubjectData }) => {
    if (
      actionKey === ACTION_TYPES.waive ||
      actionKey === ACTION_TYPES.override ||
      actionKey === ACTION_TYPES.edit
    ) {
      setWaiveAndOverrideModalState({
        visible: true,
        actionType: getActionKey(actionKey, subjectData.status),
        itemType: ModalItemType.subject,
        itemData: subjectData,
      });
    }

    if (actionKey === ACTION_TYPES.remove) {
      setRemoveWaiverOverrideModalState({
        visible: true,
        actionType:
          subjectData.status === RequirementComplianceStatus.Waived
            ? ModalActionType.waive
            : ModalActionType.override,
        itemType: ModalItemType.subject,
        itemData: {
          code: subjectData.code,
        },
      });
    }
  };
  const handleAddWaiverOverride = (params: SubmitParams) => {
    if (!waiveAndOverrideModalState.visible) return;

    const operationData = getWaiveOverrideMutationPayload({
      code: waiveAndOverrideModalState.itemData.code,
      itemType: waiveAndOverrideModalState.itemType,
      status: waiveAndOverrideModalState.itemData.status,
      actionType: waiveAndOverrideModalState.actionType,
      notes: params.notes,
      resetOn: params.expiringDate,
    });

    if (waiveAndOverrideModalState.itemType === ModalItemType.subject) {
      addWaiverOverrideSubject({
        variables: {
          requestId: requestId!,
          subject: operationData,
        },
        onCompleted: () => {
          handleCloseWaiveOverrideModal();
          refetch();
        },
      });
    }

    if (waiveAndOverrideModalState.itemType === ModalItemType.requirement) {
      addWaiverOverrideRequirement({
        variables: {
          requestId: requestId!,
          requirement: operationData,
        },
        onCompleted: () => {
          handleCloseWaiveOverrideModal();
          refetch();
        },
      });
    }
  };
  const handleRemoveWaiverOverride = () => {
    if (!removeWaiverOverrideModalState.visible) return;

    if (removeWaiverOverrideModalState.itemType === ModalItemType.subject) {
      removeWaiverOverrideSubject({
        variables: {
          requestId: requestId!,
          code: removeWaiverOverrideModalState.itemData.code,
        },
        onCompleted: () => {
          handleCloseRemoveWaiveOverrideModal();
          refetch();
        },
      });
    }

    if (removeWaiverOverrideModalState.itemType === ModalItemType.requirement) {
      removeWaiverOverrideRequirement({
        variables: {
          requestId: requestId!,
          code: removeWaiverOverrideModalState.itemData.code,
        },
        onCompleted: () => {
          handleCloseRemoveWaiveOverrideModal();
          refetch();
        },
      });
    }
  };
  const handleAddRequirementNote = (note: string) => {
    if (!noteModalState.visible) return;
    setNoteModalState({ isSubmitting: true });

    createPublicNote({
      variables: {
        attributeCode: noteModalState.itemData.code,
        id: requestId!,
        publicNotes: note,
      },
      onCompleted: () => {
        setNoteModalState({ isSubmitting: false, visible: false });
        refetch();
      },
      onError: () => {
        message.error('Failed to add a note, please try again later.');
        setNoteModalState({ isSubmitting: false });
      },
    });
  };
  const handleRemoveRequirementNote = (code: string) => {
    deletePublicNote({
      variables: {
        attributeCode: code,
        id: requestId!,
      },
      onCompleted: () => {
        refetch();
      },
      onError: () => {
        message.error('Failed to remove note, please try again later.');
      },
    });
  };

  const handleDrawerClose = () => {
    Modal.confirm({
      title: 'Are you sure you want to close the editor?',
      okText: 'Close',
      onOk: () => setIsComplianceEditorVisible(false),
    });
  };

  if (error) {
    return (
      <StyledErrorWrapper direction="vertical" justify="center">
        <Result
          status="500"
          title="500"
          subTitle="Oops! Something went wrong. Please try again later."
        />
      </StyledErrorWrapper>
    );
  }

  return (
    <>
      {requestId && (
        <StyleDrawer
          maskClosable={false}
          destroyOnClose
          title={<StyledEditorTitle>Edit compliance profile</StyledEditorTitle>}
          placement="right"
          open={isComplianceEditorVisible}
          contentWrapperStyle={{ width: '100%' }}
          onClose={handleDrawerClose}
        >
          <ComplianceEditor
            requestId={requestId}
            complianceProfileId={complianceProfile?._id}
            onClose={handleDrawerClose}
          />
        </StyleDrawer>
      )}
      {waiveAndOverrideModalState.visible && (
        <WaiveAndOverrideModal
          actionType={waiveAndOverrideModalState.actionType}
          itemType={waiveAndOverrideModalState.itemType}
          onSubmit={handleAddWaiverOverride}
          onClose={handleCloseWaiveOverrideModal}
          submitting={
            loadingWaiverOverrideSubject || loadingWaiverOverrideRequirement
          }
          defaultState={{
            notes: waiveAndOverrideModalState.itemData.notes,
            expiringDate: waiveAndOverrideModalState.itemData.resetOn,
            status: waiveAndOverrideModalState.itemData.status,
          }}
        >
          {waiveAndOverrideModalState.itemType === ModalItemType.subject ? (
            <WaiveAndOverrideModal.SubjectInfo
              name={waiveAndOverrideModalState.itemData.label}
              actionType={waiveAndOverrideModalState.actionType}
            />
          ) : (
            <span>{waiveAndOverrideModalState.itemData.label}</span>
          )}
        </WaiveAndOverrideModal>
      )}
      {removeWaiverOverrideModalState.visible && (
        <Modal
          width={384}
          closable={false}
          title={
            <Space size="middle">
              <StyledExclamationCircleFilled />
              <StyledRemoveWaiverOverrideModalContent>{`Are you sure you want to remove the ${removeWaiverOverrideModalState.actionType === ModalActionType.waive ? 'waiver' : 'override'} from this ${removeWaiverOverrideModalState.itemType === ModalItemType.subject ? 'subject' : 'requirement'}?`}</StyledRemoveWaiverOverrideModalContent>
            </Space>
          }
          open={removeWaiverOverrideModalState.visible}
          onCancel={handleCloseRemoveWaiveOverrideModal}
          onOk={handleRemoveWaiverOverride}
          confirmLoading={
            loadingWaiverOverrideSubject || loadingWaiverOverrideRequirement
          }
        />
      )}

      {noteModalState.visible && (
        <NoteModal
          isEdit={noteModalState.isEdit}
          isSubmitting={noteModalState.isSubmitting}
          onClose={() => setNoteModalState({ visible: false })}
          defaultValue={noteModalState.itemData.publicNote}
          onSubmit={handleAddRequirementNote}
        />
      )}

      <FullWidthSpace size="small" direction="vertical">
        <ComplianceSummary
          hasProfile={Boolean(complianceProfile)}
          name={complianceProfile?.name}
          onActionClick={(actionKey) => {
            if (actionKey === ComplianceSummaryActionKeys.edit) {
              setIsComplianceEditorVisible(true);
            }
          }}
          loading={loading}
        >
          <CircleProgress
            currentActiveItemsCount={
              !loading && complianceStats?.compliantSubjects !== undefined
                ? complianceStats?.compliantSubjects
                : 0
            }
            totalItemsCount={
              !loading && complianceStats?.totalSubjects !== undefined
                ? complianceStats?.totalSubjects
                : 0
            }
          />
        </ComplianceSummary>

        {loading ? (
          <>
            <Card loading />
            <Card loading />
            <Card loading />
          </>
        ) : (
          // COMPLIANCE MODULES
          complianceModules.length > 0 &&
          complianceModules.map((module) => (
            <CollapseCard key={module.code}>
              <CollapseCard.Header>
                <StyledTitle level={5}>{module.label}</StyledTitle>
              </CollapseCard.Header>
              <CollapseCard.Body>
                {/* SUBJECTS */}
                {module.subjects.length ? (
                  <FullWidthSpace direction="vertical" size="large">
                    {module.subjects.map((subject) => (
                      <Subject
                        key={subject.code}
                        subject={subject}
                        onActionClick={(actionKey) => {
                          handleSubjectActionClick({
                            actionKey,
                            subjectData: {
                              label: subject.label,
                              code: subject.code,
                              status: subject.status,
                              resetOn: subject.resetOn,
                              notes: subject.notes,
                            },
                          });
                        }}
                      >
                        {/* REQUIREMENTS */}
                        {subject.requirements.length > 0 ? (
                          subject.requirements.map((requirement) => (
                            <Row
                              key={requirement.attributeCode}
                              gutter={12}
                              align="top"
                            >
                              <Requirement
                                requirement={requirement}
                                subjectStatus={subject.status}
                                onActionClick={(actionKey) =>
                                  handleRequirementActionClick({
                                    actionKey,
                                    requirementData: {
                                      label: requirement.attributeLabel,
                                      code: requirement.attributeCode,
                                      publicNote: requirement.publicNotes,
                                      status: requirement.status,
                                      resetOn: requirement.resetOn,
                                      notes: requirement.notes,
                                    },
                                  })
                                }
                                onDeletePublicNote={() =>
                                  handleRemoveRequirementNote(
                                    requirement.attributeCode,
                                  )
                                }
                                isDeletingPublicNote={isDeletingNoteList?.includes(
                                  requirement.attributeCode,
                                )}
                              />
                            </Row>
                          ))
                        ) : (
                          <Empty description="No requirements found for this subject" />
                        )}
                      </Subject>
                    ))}
                  </FullWidthSpace>
                ) : (
                  <Empty description="No requirements found for this module" />
                )}
              </CollapseCard.Body>
            </CollapseCard>
          ))
        )}
      </FullWidthSpace>
    </>
  );
}

const StyledErrorWrapper = styled(Flex)`
  height: 100%;
`;

const StyledTitle = styled(Typography.Title)`
  margin-bottom: 0 !important;
`;

// Replicating Modal.warning
const StyledExclamationCircleFilled = styled(ExclamationCircleFilled)`
  font-size: 22px;
  color: #faad14;
`;

const StyledRemoveWaiverOverrideModalContent = styled.div`
  font-size: 16px;
`;

const StyleDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;
const StyledEditorTitle = styled.h3`
  margin: 0;
`;
