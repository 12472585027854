import { mapTableParamsToLegacyDocumentsReportParams } from '../containers/DocumentsList/utils';
import type { TableState } from '../containers/DocumentsSection';

const flatObject = (
  obj: Record<
    string,
    string | Record<string, string | string[] | number | undefined>
  >,
): { [x: string]: string | string[] } => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      // @ts-expect-error - TS is not able to infer { [key]: obj[key] } for obj[key] === 'string'
      ...(typeof obj[key] === 'string' ? { [key]: obj[key] } : obj[key]),
    }),
    {},
  );
};

const mapPaginationToLegacyURLPagination = (
  tablePagination: TableState['pagination'],
) => {
  return {
    page: tablePagination.currentPage,
    size: tablePagination.pageSize,
  };
};

export const mapTableStateToURLSearchParams = (
  tableState: TableState,
): Record<string, unknown> => {
  const reportParams = mapTableParamsToLegacyDocumentsReportParams({
    filters: tableState.filters,
    sorting: tableState.sorting,
  });

  const URLData = {
    ...(tableState.reportId ? { report: tableState.reportId } : {}),
    pagination: mapPaginationToLegacyURLPagination(tableState.pagination),
    reportParams: reportParams,
  };

  return flatObject(URLData);
};
