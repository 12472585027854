import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { isValidEmail } from '@trustlayer/common';
import { Button, Divider, Modal, Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useContactsEmails } from './hooks';
import { useAssignContact } from './hooks/useAssignContact';

type AddContactModalProps = {
  primaryRecordId: string;
  onClose: () => void;
  onCreateContactClick?: (emailInputValue: string) => void;
  onAssignCompleted?: () => void;
};

export const AddContactModal = ({
  primaryRecordId,
  onClose,
  onCreateContactClick,
  onAssignCompleted,
}: AddContactModalProps) => {
  const [selectedContactId, setSelectedContactId] = useState('');
  const [inputValue, setInputValue] = useState('');

  const handleOnSelect = (
    _value: string,
    option: { key: string; value: string },
  ) => {
    setSelectedContactId(option.key);
    setInputValue(option.value);
  };

  const handleOnSearch = (searchedValue: string) => {
    setInputValue(searchedValue);
    filterContactsByEmail(searchedValue);
  };

  const { contactsList, filterContactsByEmail, loading } = useContactsEmails();
  const { assignContact, loading: assignContactLoading } = useAssignContact({
    primaryRecordId,
  });

  const PopupContent = (menu: any) => {
    return (
      <StyledPopupContent>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <section className="suggestion">
          {contactsList.length ? (
            <span>Enter a valid email address to add a new contact</span>
          ) : (
            <Button
              type="dashed"
              block
              icon={<PlusOutlined />}
              onClick={() => {
                onCreateContactClick?.(inputValue);
              }}
              disabled={!isValidEmail(inputValue)}
            >
              Create new contact
            </Button>
          )}
        </section>
      </StyledPopupContent>
    );
  };

  return (
    <Modal
      open
      title="Add Contact"
      okText="Add contact"
      okButtonProps={{
        disabled: !selectedContactId || assignContactLoading,
        loading: assignContactLoading,
      }}
      onOk={() => {
        assignContact({
          contactId: selectedContactId,
          onCompleted: onAssignCompleted,
        });
      }}
      onCancel={() => {
        onClose?.();
      }}
    >
      <StyledContent>
        <>
          <span className="label">Email</span>
          <StyledSelect
            allowClear
            showSearch
            loading={loading}
            value={inputValue}
            onSearch={handleOnSearch}
            onSelect={handleOnSelect}
            dropdownRender={PopupContent}
          >
            {contactsList?.map((option) => (
              <Select.Option
                key={option._id}
                value={option.email || ''}
                optionLabelProp="label"
              >
                <div className="option">
                  <UserOutlined />
                  <span>{`${option.contactPersonName} - ${option.email}`}</span>
                </div>
              </Select.Option>
            ))}
          </StyledSelect>
        </>
      </StyledContent>
    </Modal>
  );
};

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledContent = styled.section`
  padding: 24px 0;
  
  .label {
    display: block;
    margin-bottom: 8px;
  }
`;

const StyledPopupContent = styled.section`
  .option {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .suggestion {
    color: ${({ theme }) => theme.colors.subtitleText};
    padding: 5px 12px;
    font-size: 12px;
  }
`;
