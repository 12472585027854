import useSearchParams from '@common/hooks/useSearchParams';
import { Conversations } from '@modules/primary-records';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const normalizeSearchParams = (param: string | (string | null)[] | null) => {
  if (Array.isArray(param)) {
    return param[0] || undefined;
  }

  return param || undefined;
};

export function PrimaryRecordMessages() {
  const { searchParams } = useSearchParams();
  const { recordId } = useParams<PrimaryRecordsRoutesParams>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const normalizedConversationIdParam = normalizeSearchParams(
    searchParams.conversation,
  );
  const initialConversation = normalizedConversationIdParam
    ? {
        id: normalizedConversationIdParam,
        messageId: normalizeSearchParams(searchParams.message),
      }
    : undefined;
  const handleConversationsChange = ({
    conversationId,
    messageId,
  }: {
    conversationId: string;
    messageId?: string;
  }) => {
    const messageParam = messageId ? `&message=${messageId}` : '';

    const conversationParam = conversationId
      ? `conversation=${conversationId}`
      : '';

    navigate(`${pathname}?${conversationParam}${messageParam}`, {
      replace: true,
    });
  };

  return (
    <Conversations
      recordId={recordId!}
      initialConversation={initialConversation}
      onChange={handleConversationsChange}
    />
  );
}
