import { InfoCircleOutlined } from '@ant-design/icons';
import { Checkbox, Input, Modal, Popover, Typography } from 'antd';
import dayjs, { type Dayjs } from 'dayjs';
import capitalize from 'lodash/capitalize';
import { type PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import FullWidthDatePicker from '@common/components/FullWidthDatePicker';
import FullWidthSpace from '@common/components/FullWidthSpace';
import { utc } from '@common/utils/date';
import { defaultDateFormats } from '@common/utils/filter-helpers';
import { RequirementComplianceStatus } from '@trustlayer/common';
import {
  getSubjectInfoSubtitle,
  isDateDisabled,
} from './WaiveAndOverrideModal.utils';
import { ACTION_NOUNS_MAP, ActionType, ItemType } from './constants';

export type SubmitParams = {
  notes?: string;
  expiringDate: string | null;
  applyToAllContexts: boolean;
};

type WaiveAndOverrideModalProps = PropsWithChildren<{
  actionType: ActionType;
  itemType: ItemType;
  defaultState?: {
    status?: RequirementComplianceStatus;
    notes?: string;
    expiringDate?: string;
  };
  onSubmit: (params: SubmitParams) => void;
  onClose: () => void;
  submitting?: boolean;
}>;

const { TextArea } = Input;

export const WaiveAndOverrideModal = ({
  actionType = ActionType.waive,
  itemType = ItemType.requirement,
  defaultState,
  children,
  onSubmit = () => {},
  onClose = () => {},
  submitting,
}: WaiveAndOverrideModalProps) => {
  const [notes, setNotes] = useState(defaultState?.notes ?? '');
  const [applyToAllContexts, setToApplyAllContexts] = useState(false);
  const [expiringDate, setExpiringDate] = useState<Dayjs | null>(
    defaultState?.expiringDate ? dayjs(defaultState?.expiringDate) : null,
  );

  const actionTypeLabel = actionType;
  const itemTypeLabel = itemType;
  const isEditing =
    defaultState?.status === RequirementComplianceStatus.Waived ||
    defaultState?.status === RequirementComplianceStatus.Overridden;
  const capitilizedActionTypeLabel = capitalize(actionTypeLabel);
  const okLabel = isEditing ? 'Edit' : capitilizedActionTypeLabel;
  const title = `${isEditing ? 'Edit ' : ''}${capitilizedActionTypeLabel} ${itemTypeLabel}`;

  const handleSubmit = () => {
    onSubmit({
      notes,
      expiringDate: expiringDate ? utc(expiringDate).toISOString() : null,
      applyToAllContexts,
    });
  };

  return (
    <Modal
      open
      title={title}
      centered={true}
      width={685}
      okText={okLabel}
      onOk={handleSubmit}
      onCancel={onClose}
      okButtonProps={{ loading: submitting }}
    >
      <FullWidthSpace direction="vertical" size="middle">
        <Typography.Text>
          {`Please confirm that you want to ${actionTypeLabel} the ${itemTypeLabel} below:`}
        </Typography.Text>
        <StyledSubjectInfoWrapper>{children}</StyledSubjectInfoWrapper>
        <label>Notes:</label>
        <TextArea
          rows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
        <label>{`Remove ${ACTION_NOUNS_MAP[actionType]} on:`}</label>
        <FullWidthDatePicker
          onChange={setExpiringDate}
          value={expiringDate}
          disabledDate={isDateDisabled}
          format={defaultDateFormats}
        />
        <FullWidthSpace align="center" size="small">
          <Checkbox
            id="apply-across-all-projects"
            checked={applyToAllContexts}
            onChange={() =>
              setToApplyAllContexts((applyToAllContexts) => !applyToAllContexts)
            }
          />
          <label htmlFor="apply-across-all-projects">
            {`Apply ${actionTypeLabel} across all projects`}
          </label>
          <Popover
            content={`This ${actionTypeLabel} will apply to all existing and future projects`}
          >
            <InfoCircleOutlined />
          </Popover>
        </FullWidthSpace>
      </FullWidthSpace>
    </Modal>
  );
};

WaiveAndOverrideModal.SubjectInfo = ({
  actionType,
  name,
}: {
  actionType: ActionType;
  name: string;
}) => {
  const actionTypeLabel = actionType;
  return (
    <>
      <StyledSubjectLabel>{name}</StyledSubjectLabel>
      <StyledInfo>{getSubjectInfoSubtitle(actionTypeLabel)}</StyledInfo>
    </>
  );
};

const StyledSubjectLabel = styled.p`
  font-weight: 600;
  margin-bottom: 5px;
`;

const StyledInfo = styled.p`
  margin: 0;
`;

const StyledSubjectInfoWrapper = styled.div`
  padding: 10px;
  border: 1px solid ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme }) => theme.colors.blueGray};
  border-radius: 4px;
`;
