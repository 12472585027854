import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Skeleton } from 'antd';
import type { ReactNode } from 'react';
import styled from 'styled-components';

export function PageTitle({
  title,
  icon,
  iconSize,
}: {
  title?: string;
  icon?: ReactNode;
  iconSize?: 'large' | 'small' | 'default';
}) {
  return (
    <PageTitleWrapper>
      {icon && <StyledAvatar size={iconSize} shape="square" icon={icon} />}
      <StyledH1>{title}</StyledH1>
    </PageTitleWrapper>
  );
}

PageTitle.Composite = ({
  leftTitle,
  middleIcon,
  rightTitle,
}: {
  leftTitle: ReactNode & { type: typeof PageTitle };
  middleIcon: ReactNode;
  rightTitle: ReactNode;
}) => (
  <StyledCompositeWrapper>
    <PageTitleWrapper>{leftTitle}</PageTitleWrapper>
    {middleIcon}
    <PageTitleWrapper>{rightTitle}</PageTitleWrapper>
  </StyledCompositeWrapper>
);

PageTitle.Skeleton = () => (
  <PageTitleWrapper>
    <StyledAvatar shape="square" icon={<LoadingOutlined />} />
    <Skeleton.Input style={{ width: 100 }} active />
  </PageTitleWrapper>
);

const StyledH1 = styled.h1`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: 0;
  padding: 0;
  text-transform: capitalize;
`;

const PageTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.gray};

   ${({ theme, size }) => {
     switch (size) {
       case 'small':
         return `
          color: ${theme.colors.mineShaft};
        `;
       default:
         return `
          color: ${theme.colors.primary};
        `;
     }
   }}
`;

const StyledCompositeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
