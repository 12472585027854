import { LoadingOutlined } from '@ant-design/icons';
import { Button, Select, message as notify } from 'antd';
import * as R from 'ramda';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import * as DocumentActions from '../actions';
import { usePartiesByName } from '../hooks';

const AssignDocumentToParty = ({
  documentId,
  onAfterAssign,
  isShownOnModal = false,
}) => {
  const dispatch = useDispatch();

  const {
    fetchPartyData,
    loading,
    handleChange,
    partyData,
    searchPartyName,
    setSearchPartyName,
  } = usePartiesByName();

  const [partyId, setPartyId] = useState('');

  const assignDocumentsToParty = async (documentIds, partyId) => {
    if (partyId) {
      await dispatch(
        DocumentActions.assignDocumentsToParty({
          documentIds,
          partyId,
        }),
      );

      isShownOnModal && setSearchPartyName([]);

      if (onAfterAssign) {
        onAfterAssign({ documentId, partyId });
      }
    } else {
      notify.error('Choose party');
    }
  };

  return (
    <>
      <AssignDocumentToParty.Select
        allowClear
        showSearch
        showArrow={false}
        labelInValue
        placeholder="Assign this document to a party..."
        value={searchPartyName}
        notFoundContent={loading && <LoadingOutlined />}
        onChange={(value) => {
          handleChange(value);
          setPartyId(R.propOr('', 'key', value));
        }}
        onSearch={fetchPartyData}
        filterOption={false}
      >
        {partyData.map((party) => (
          <Select.Option key={party._id} value={party._id}>
            {party.name}
          </Select.Option>
        ))}
      </AssignDocumentToParty.Select>
      <Button
        type="primary"
        onClick={() => {
          assignDocumentsToParty([documentId], partyId);
        }}
      >
        Assign
      </Button>
    </>
  );
};

AssignDocumentToParty.Select = styled(Select)`
  margin: 0 10px 0 0 !important;
  width: 300px;
`;

export default AssignDocumentToParty;
