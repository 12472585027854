import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip, message } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import { CellActions, Table, type TableRef } from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import { ContactAvatar } from './components/ContactAvatar';
import { CONTACTS_COLUMN_FIELDS } from './constants';
import { AddContactModal } from './containers/AddContactModal';
import { CreateContactModal } from './containers/CreateContactModal';
import { UpdateContactModal } from './containers/UpdateContactModal';
import { type Contact, useContacts, useDeleteContact } from './hooks';

type ContactsTableProps = {
  recordId: string;
};

export const ContactsTable = ({ recordId }: ContactsTableProps) => {
  const { getContacts } = useContacts({ primaryRecordId: recordId });
  const { deleteContact } = useDeleteContact({ primaryRecordId: recordId });

  const contactsTableRef = useRef<TableRef>(null);

  const [searchedEmailValue, setSearchedEmailValue] = useState('');

  const [isAddContactModalVisible, setIsAddContactModalVisible] =
    useState(false);

  const [isCreateContactModalVisible, setIsCreateContactModalVisible] =
    useState(false);

  const [contactToEdit, setContactToEdit] = useState<Contact | undefined>();

  const columns = useMemo(
    () => [
      {
        headerName: 'Name',
        field: CONTACTS_COLUMN_FIELDS.name,
        sortable: false,
        filter: 'agTextColumnFilter',
        filterParams: {
          filterOptions: ['contains'],
        },
        cellDataType: 'text',
        valueGetter: ({ data }: { data: any }) => data,
        cellRenderer: ({ value }: { value: Contact }) => {
          return (
            <StyledName>
              <ContactAvatar
                avatarURL={value.avatar}
                isPrimary={value.primary}
                name={value.contactPersonName}
              />
              <span>{value.contactPersonName}</span>
            </StyledName>
          );
        },
      },
      {
        headerName: 'Email',
        field: CONTACTS_COLUMN_FIELDS.email,
        sortable: false,
        cellDataType: 'text',
        valueGetter: ({ data }: { data: Contact }) => data.email,
      },
      {
        headerName: 'Company',
        field: CONTACTS_COLUMN_FIELDS.company,
        sortable: false,
        cellDataType: 'text',
        valueGetter: ({ data }: { data: Contact }) => data?.companyName,
      },
      {
        headerName: 'Actions',
        field: CONTACTS_COLUMN_FIELDS.actions,
        lockPosition: 'right' as const,
        pinned: 'right' as const,
        width: 90,
        maxWidth: 90,
        sortable: false,
        valueGetter: ({ data }: { data: Contact }) => data,
        cellRenderer: ({ value }: { value: Contact }) => {
          return (
            <CellActions>
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label: 'Send email',
                      code: 'email',
                      disabled: false,
                    },
                    {
                      label: 'Set as primary contact',
                      code: 'primary',
                      disabled: false,
                    },
                    {
                      label: 'Edit',
                      code: 'edit',
                      disabled: false,
                      onClick: () => {
                        setContactToEdit(value);
                      },
                    },
                    {
                      label: (
                        <Tooltip
                          title={
                            <StyledTextTooltip>
                              <span>
                                Unable to remove when set
                                <br />
                                as primary contact
                              </span>
                            </StyledTextTooltip>
                          }
                          trigger={value.primary ? 'hover' : 'none'}
                          mouseEnterDelay={0.5}
                          zIndex={9999}
                        >
                          Remove
                        </Tooltip>
                      ),
                      code: 'remove',
                      alert: true,
                      disabled: value.primary,
                      onClick: () => {
                        deleteContact(value._id!, {
                          onCompleted: () => {
                            contactsTableRef.current?.refreshTable();
                          },
                          onError: () => {
                            message.error(
                              `Failed to remove the contact of ${value.contactPersonName} from the record.`,
                            );
                          },
                        });
                      },
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          );
        },
      },
    ],
    [deleteContact],
  );

  return (
    <StyledWrapper>
      {isCreateContactModalVisible && (
        <CreateContactModal
          primaryRecordId={recordId}
          emailValue={searchedEmailValue}
          onCompleted={() => {
            contactsTableRef.current?.refreshTable();
            setIsCreateContactModalVisible(false);
          }}
          onClose={() => {
            setIsCreateContactModalVisible(false);
          }}
        />
      )}
      {contactToEdit && (
        <UpdateContactModal
          primaryRecordId={recordId}
          contactToUpdate={contactToEdit}
          onCompleted={() => {
            contactsTableRef.current?.refreshTable();
            setContactToEdit(undefined);
          }}
          onClose={() => {
            setContactToEdit(undefined);
          }}
        />
      )}
      {isAddContactModalVisible && (
        <AddContactModal
          primaryRecordId={recordId}
          onAssignCompleted={() => {
            contactsTableRef.current?.refreshTable();
          }}
          onCreateContactClick={(emailValue) => {
            setSearchedEmailValue(emailValue);
            setIsCreateContactModalVisible(true);
            setIsAddContactModalVisible(false);
          }}
          onClose={() => {
            setIsAddContactModalVisible(false);
          }}
        />
      )}
      <TableHeader>
        <PageTitle title="Contacts" />
        <div className="top-right-actions">
          <Button
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAddContactModalVisible(true);
            }}
          >
            New Contact
          </Button>
        </div>
        <div className="bottom-left-actions">
          <StyledSearchInputWrapper>
            <SearchInput
              onSearch={(value: string) => {
                contactsTableRef.current?.setFilters({
                  name: {
                    filterType: 'text',
                    filter: value,
                    type: 'contains',
                  },
                });
              }}
            />
          </StyledSearchInputWrapper>
        </div>
      </TableHeader>
      <StyledTable>
        <Table
          ref={contactsTableRef}
          isSideBarDisbaled
          columnDefs={columns}
          getRowData={getContacts}
        />
      </StyledTable>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledSearchInputWrapper = styled.div`
  width: 280px;
`;

const StyledName = styled.section`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledTextTooltip = styled.div`
  text-align: center;
`;
