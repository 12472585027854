import { useQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import {
  getMessagesGruopByDateMap,
  normalizeMessagesList,
} from '../Messages.utils';

export const MESSAGES_QUERY = graphql(`
  query ConversationMessages(
    $conversationInput: ConversationInput!
    $input: ConversationMessagesInput
  ) {
    conversation(input: $conversationInput) {
      _id
      messages(input: $input) {
        totalCount
        nodes {
          _id
          request
          sendgridMessageId
          type
          outputHtml
          status
          message
          createdAt
          senderType
          mostRecentUniqueSendgridEvents {
            _id
            status
            date
            email
          }
          updatedAt
          attachments {
            totalCount
            nodes {
              _id
              s3Key
              url
              friendlyName
            }
          }
          recipients
          senderContact {
            _id
            contactPersonName
            email
            avatar
          }
          senderUser {
            _id
            email
            profile {
              name
              avatar
            }
          }
          issues {
            nodes {
              _id
              email
              resendable
              ignorable
              resolved
              contact {
                _id
              }
            }
          }
          data {
            ... on MessageResent {
              partyContact {
                _id
                email
              }
            }
            ... on ContactDeleted {
              partyContact {
                _id
                email
              }
            }
            ... on ContactRemovedFromConversation {
              partyContact {
                _id
                email
              }
            }
            ... on ContactEmailUpdatedResent {
              partyContactAfterUpdate {
                _id
                email
              }
            }
          }
        }
      }
    }
  }
`);

/**
 * @note keeping the current legacy behavior until the InfiniteScroll implementation
 */
const DEFAULT_PAGE_SIZE = 500;

const DEFAULT_INPUT = {
  first: DEFAULT_PAGE_SIZE,
  sort: {
    createdAt: -1,
  },
};

export const useMessages = ({ conversationId }: { conversationId: string }) => {
  const { data, loading } = useQuery(MESSAGES_QUERY, {
    variables: {
      conversationInput: { id: conversationId },
      input: DEFAULT_INPUT,
    },
  });

  const messages = normalizeMessagesList(
    // @ts-expect-error message.data is not typed properly
    data?.conversation?.messages?.nodes || [],
  );

  return {
    messages,
    messagesByDateMap: getMessagesGruopByDateMap(messages),
    loading,
  };
};
