import { COMPLIANCE_STATUS } from './RecordsCompliance.constants';

export function getComplianceStatusFromValue(
  value: number,
  total: number,
): COMPLIANCE_STATUS {
  if (value === 0 && total === 0) {
    return COMPLIANCE_STATUS.PROGRESS;
  }

  if (value === total) {
    return COMPLIANCE_STATUS.GOOD;
  }

  if (value === 0) {
    return COMPLIANCE_STATUS.BAD;
  }

  return COMPLIANCE_STATUS.PROGRESS;
}
