import { Modal, Select, Skeleton } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useDocumentTypes } from './hooks/useDocumentTypes';

type AddDocumentChecklistModalProps = {
  visible: boolean;
  onAddDocumentChecklist: (typeId: string) => void;
  onClose: () => void;
  excludedDocumentTypeIds: string[];
};

export const AddDocumentChecklistModal = ({
  visible,
  onAddDocumentChecklist,
  onClose,
  excludedDocumentTypeIds,
}: AddDocumentChecklistModalProps) => {
  const [typeId, setTypeId] = useState('');

  const { documentTypes, loading } = useDocumentTypes({
    skip: !visible,
    excludedDocumentTypeIds,
  });

  return (
    <Modal
      destroyOnClose
      open={visible}
      title="Add document checklist"
      okText="Add document checklist"
      onOk={() => {
        onAddDocumentChecklist(typeId);
      }}
      okButtonProps={{
        // @ts-expect-error - Cypress selector
        'data-cy': 'confirmButton',
        disabled: typeId === '',
      }}
      onCancel={() => {
        onClose();
      }}
    >
      <p>
        A document checklist helps you define requirements that documents of a
        specific type should meet.
      </p>

      {loading ? (
        <Skeleton.Input style={{ width: '100%' }} active />
      ) : (
        <StyledSelect
          data-cy="documentTypeSelect"
          placeholder="Select a document type..."
          onChange={(selectedTypeId) => setTypeId(selectedTypeId)}
        >
          {documentTypes.map((type) => (
            <Select.Option value={type._id} key={type._id}>
              {type.name}
            </Select.Option>
          ))}
        </StyledSelect>
      )}
    </Modal>
  );
};

const StyledSelect = styled(Select<string>)`
  width: 100% !important;
`;
