import { SendOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { forwardRef, useMemo } from 'react';

import {
  CellActions,
  type CustomSelectionChangedEvent,
  DateCustomFilter,
  type FilterChangedParams,
  LinkCell,
  Table,
  type TableRef,
  type TableSortingState,
  type TableState,
} from '@common/components/Table';
import {
  COMMON_CELL_CLASSES,
  NUMBER_FILTER_OPTION_TYPES,
} from '@common/components/Table/constants';
import { HideForViewerRole } from '@modules/organization-member/containers/HideForRoles';

import type { PrimaryRecord } from '@graphql/types/graphql';
import {
  type ActionType,
  PRIMARY_RECORDS_ACTION_TYPES,
} from '@modules/primary-records';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RecordsActiveDocuments } from './components/RecordsActiveDocuments';
import { RecordsCompliance } from './components/RecordsCompliance';
import { RecordsCompliancePopoverContent } from './components/RecordsCompliancePopoverContent';
import { RecordsTags } from './components/RecordsTags';
import { RecordsTotalEmails } from './components/RecordsTotalEmails';
import {
  COLUMN_FIELDS,
  CONTACT_EMAIL_STATUS,
  OVERRIDDEN_STATUS,
  REVIEW_STATUS,
  SEVERITY_STATUS,
  WAIVERS_STATUS,
} from './constants';
import { ContextRecordsFilter } from './containers/ContextRecordFilter';
import { PrimaryRecordTypesFilter } from './containers/PrimaryRecordTypesFilter';
import { TagsFilter } from './containers/TagsFilter';
import { usePrimaryRecords } from './hooks';

export type RecordsTableRef = TableRef;

type RecordsTableProps = {
  primaryObjectSlug: string;
  onSelectionChanged: (params: CustomSelectionChangedEvent) => void;
  onFilterChanged: (params: FilterChangedParams) => void;
  onSortChanged: (params: TableSortingState) => void;
  onPaginationChanged: (params: any) => void;
  onRowActionClick: (actionType: ActionType, recordId: string) => void;
  tableState?: TableState;
};

type SetFilterValue = {
  code: string;
  name: string;
};

const commonSetFilterParams = {
  keyCreator: ({ value }: { value: SetFilterValue }) => value.code,
  valueFormatter: ({ value }: { value: SetFilterValue }) => value.name,
};

export const RecordsTable = forwardRef<RecordsTableRef, RecordsTableProps>(
  (
    {
      primaryObjectSlug,
      onSelectionChanged,
      onFilterChanged,
      onSortChanged,
      onPaginationChanged,
      onRowActionClick,
      tableState = { filters: {}, sorting: {}, pagination: {} },
    },
    recordsTableRef,
  ) => {
    const { getPrimaryRecords } = usePrimaryRecords({ primaryObjectSlug });

    // biome-ignore lint/correctness/useExhaustiveDependencies: More Deps Than Needed
    const columns = useMemo(
      () => [
        {
          headerName: 'Name',
          field: COLUMN_FIELDS.name,
          width: 400,
          sortable: true,
          pinned: 'left' as const,
          checkboxSelection: true,
          lockPosition: 'left' as const,
          filter: 'agTextColumnFilter',
          filterParams: {
            filterOptions: ['contains'],
          },
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          cellRenderer: ({ value: record }: { value: PrimaryRecord }) => {
            const route = `${record._id}/requests`;
            return record?._id && <LinkCell to={route}>{record.name}</LinkCell>;
          },
        },
        {
          headerName: 'Tags',
          field: COLUMN_FIELDS.tags,
          minWidth: 150,
          filter: TagsFilter,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data?.tags,
          cellRenderer: ({ value }: { value: PrimaryRecord['tags'] }) => {
            return <RecordsTags tags={value.nodes} />;
          },
        },
        {
          headerName: 'Status',
          field: COLUMN_FIELDS.complianceStatus,
          sortable: true,
          minWidth: 150,
          maxWidth: 200,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          cellRenderer: ComplianceCountCell,
        },
        {
          headerName: 'Total Emails',
          field: COLUMN_FIELDS.totalEmails,
          minWidth: 150,
          maxWidth: 200,
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) => ({
            ...data?.stats.messages,
            recordId: data._id,
          }),
          cellRenderer: ({
            value,
          }: {
            value: PrimaryRecord['stats']['messages'] & {
              recordId: PrimaryRecord['_id'];
            };
          }) => {
            const { totalCount, totalIssuesCount, recordId } = value;
            return (
              <Link to={`${recordId}/messages`}>
                <RecordsTotalEmails
                  hasWarnings={totalIssuesCount > 0}
                  value={totalCount}
                />
              </Link>
            );
          },
        },
        {
          headerName: 'Active Documents',
          field: COLUMN_FIELDS.activeDocuments,
          minWidth: 150,
          maxWidth: 200,
          sortable: false,
          filter: 'agNumberColumnFilter',
          filterParams: {
            filterOptions: [
              NUMBER_FILTER_OPTION_TYPES.equals,
              NUMBER_FILTER_OPTION_TYPES.greaterThan,
              NUMBER_FILTER_OPTION_TYPES.lessThan,
            ],
          },
          cellClass: COMMON_CELL_CLASSES.alignCenter,
          valueGetter: ({ data }: { data: PrimaryRecord }) =>
            data?.stats.documents,
          cellRenderer: ({
            value,
          }: { value: PrimaryRecord['stats']['documents'] }) => {
            const { reviewedCount, totalCount } = value;

            return (
              <RecordsActiveDocuments
                totalDocuments={totalCount}
                activeDocuments={reviewedCount - totalCount}
              />
            );
          },
        },
        {
          headerName: 'Review Status',
          field: COLUMN_FIELDS.reviewStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              { code: REVIEW_STATUS.Reviewed, name: 'Reviewed' },
              { code: REVIEW_STATUS.ToReview, name: 'To Review' },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Flags',
          field: COLUMN_FIELDS.flagsSeverity,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              { name: 'High severity', code: SEVERITY_STATUS.High },
              { name: 'Medium severity', code: SEVERITY_STATUS.Medium },
              { name: 'Low severity', code: SEVERITY_STATUS.Low },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Types',
          field: COLUMN_FIELDS.types,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: PrimaryRecordTypesFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Context Records',
          field: COLUMN_FIELDS.contextRecords,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: ContextRecordsFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        //! Removed this filter until we have a clear path on how to implement it
        // {
        //   headerName: 'Automations',
        //   field: COLUMN_FIELDS.automations,
        //   cellDataType: 'text',
        //   sortable: false,
        //   hide: true,
        //   filter: AutomationsFilter,
        //   valueGetter: ({ data }: { data: any }) => data.record?.name,
        // },
        {
          headerName: 'Waivers Status',
          field: COLUMN_FIELDS.waiversStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: WAIVERS_STATUS.WithRequirements,
                name: 'With Waived Requirements',
              },
              {
                code: WAIVERS_STATUS.WithoutRequirements,
                name: 'Without Waived Requirements',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Overrides Status',
          field: COLUMN_FIELDS.overridesStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: OVERRIDDEN_STATUS.WithOverriddenRequirements,
                name: 'With Overridden Requirements',
              },
              {
                code: OVERRIDDEN_STATUS.WithoutOverriddenRequirements,
                name: 'Without Overridden Requirements',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Expiration Date',
          field: COLUMN_FIELDS.nextComplianceExpiration,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Last Activity Date',
          field: COLUMN_FIELDS.lastActivity,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Last Request Sent',
          field: COLUMN_FIELDS.lastRequest,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Message Failures Date',
          field: COLUMN_FIELDS.messageFailures,
          sortable: true,
          hide: true,
          filter: DateCustomFilter,
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Contact Email Status',
          field: COLUMN_FIELDS.contactEmailStatus,
          cellDataType: 'text',
          sortable: false,
          hide: true,
          filter: 'agSetColumnFilter',
          filterParams: {
            ...commonSetFilterParams,
            values: [
              {
                code: CONTACT_EMAIL_STATUS.WithContactEmail,
                name: 'With Contact Email',
              },
              {
                code: CONTACT_EMAIL_STATUS.WithoutContactEmail,
                name: 'Without Contact Email',
              },
            ],
          },
          valueGetter: ({ data }: { data: any }) => data.record?.name,
        },
        {
          headerName: 'Added',
          field: COLUMN_FIELDS.createdAt,
          sortable: true,
          hide: true,
        },
        {
          headerName: '',
          field: COLUMN_FIELDS.actions,
          lockPosition: 'right' as const,
          pinned: 'right' as const,
          width: 90,
          maxWidth: 100,
          sortable: false,
          valueGetter: ({ data }: { data: PrimaryRecord }) => data,
          //eslint-disable-next-line
          cellRenderer: ({ value: data }: { value: PrimaryRecord }) => (
            <CellActions>
              <CellActions.Button icon={<SendOutlined />} />
              <HideForViewerRole>
                <CellActions.ThreeDotsMenu
                  menuItems={[
                    {
                      label: 'Enable Automation',
                      code: PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.enableAutomation,
                          data._id,
                        );
                      },
                    },
                    {
                      label: 'Disable Automation',
                      code: 'disableAutomation',
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.disableAutomation,
                          data._id,
                        );
                      },
                    },
                    {
                      label: 'Archive',
                      code: 'archive',
                      disabled: false,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.archive,
                          data._id,
                        );
                      },
                    },
                    {
                      label: 'Delete',
                      code: 'delete',
                      disabled: false,
                      alert: true,
                      onClick: () => {
                        onRowActionClick(
                          PRIMARY_RECORDS_ACTION_TYPES.delete,
                          data._id,
                        );
                      },
                    },
                  ]}
                />
              </HideForViewerRole>
            </CellActions>
          ),
        },
      ],
      //eslint-disable-next-line
      [primaryObjectSlug],
    );

    return (
      <Table
        ref={recordsTableRef}
        columnDefs={columns}
        getRowData={getPrimaryRecords}
        onSelectionChanged={onSelectionChanged}
        onFilterChanged={onFilterChanged}
        onSortChanged={onSortChanged}
        onPaginationChanged={onPaginationChanged}
        tableState={tableState}
      />
    );
  },
);

const ComplianceCountCell = ({
  value,
}: { value: Pick<PrimaryRecord, 'stats' | '_id' | 'name'> }) => {
  const { compliantCount, totalCount } = value.stats.requestRecords;

  return (
    <Popover
      content={<RecordsCompliancePopoverContent primaryRecordId={value._id} />}
    >
      <StyledRecordsComplianceWrapper>
        <RecordsCompliance compliants={compliantCount} total={totalCount} />
      </StyledRecordsComplianceWrapper>
    </Popover>
  );
};

const StyledRecordsComplianceWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;
