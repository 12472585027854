import { FileSearchOutlined } from '@ant-design/icons';
import { Divider, Dropdown, type MenuProps, theme } from 'antd';
import {
  type ReactNode,
  cloneElement,
  useEffect,
  useMemo,
  useState,
} from 'react';
import styled from 'styled-components';

import {
  extractElementsByKeyFromAntdMenu,
  getActiveMenuItem,
} from './CustomDropdown.utils';

type MenuOnClikEvent = {
  key: string;
  keyPath: string[];
  domEvent: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>;
};

const CustomDropdownMenu = ({
  menu,
  actionButtonMenu,
}: {
  menu: ReactNode;
  actionButtonMenu?: ReactNode;
}) => {
  const { token } = theme.useToken();

  if (!Boolean(actionButtonMenu)) return menu as React.ReactElement;

  return (
    <StyleCustomDropdown
      style={{
        backgroundColor: token.colorBgElevated,
        boxShadow: token.boxShadowSecondary,
        borderRadius: token.borderRadius,
      }}
    >
      <div style={{ padding: 8 }}>{actionButtonMenu}</div>
      <Divider style={{ margin: 0 }} />
      {cloneElement(menu as React.ReactElement, {
        style: { boxShadow: 'none', maxHeight: '450px', overflow: 'auto' },
      })}
    </StyleCustomDropdown>
  );
};

type CustomDropdownProps = {
  actionsMenu: MenuProps['items'];
  mainMenu: MenuProps['items'];
  value?: string;
  mainMenuButton?: ReactNode;
  loading?: boolean;
  onChange?: (value: string) => void;
};

export const CustomDropdown = ({
  actionsMenu,
  mainMenuButton,
  mainMenu,
  value,
  loading,
  onChange,
}: CustomDropdownProps) => {
  const [activeKey, setActiveKey] = useState<string>();

  useEffect(() => {
    setActiveKey(value);
  }, [value]);

  const menuItems = useMemo(() => {
    return extractElementsByKeyFromAntdMenu(mainMenu, 'key');
  }, [mainMenu]);

  const handleOnClickMenuItem = (menuItem: MenuOnClikEvent) => {
    setActiveKey(menuItem.key);
    onChange?.(menuItem.key);
  };

  return (
    <Dropdown.Button
      loading={loading}
      data-cy="custom-dropdown"
      trigger={['click']}
      disabled={loading}
      menu={{
        items: actionsMenu,
      }}
      buttonsRender={([leftButton, rightButton]) => [
        <Dropdown
          menu={{
            items: mainMenu,
            onClick: handleOnClickMenuItem,
            // defaultSelectedKeys: activeKey && [activeKey],
            selectedKeys: activeKey ? [activeKey] : undefined,
            selectable: true,
          }}
          overlayStyle={{ zIndex: 1000 }} // To avoid being overlapped by modals
          trigger={['click']}
          dropdownRender={(menu) => (
            <CustomDropdownMenu menu={menu} actionButtonMenu={mainMenuButton} />
          )}
          key="dropdown-menu"
        >
          {leftButton}
        </Dropdown>,
        rightButton,
      ]}
    >
      <StyledactiveKey>
        <FileSearchOutlined className="icon" />

        <span className="label">
          {getActiveMenuItem(menuItems, activeKey)?.label}
        </span>
      </StyledactiveKey>
    </Dropdown.Button>
  );
};

const StyledactiveKey = styled.span`
  .icon {
    margin-right: 5px;
    color: ${({ theme }) => theme.colors.blue};
  }
`;

const StyleCustomDropdown = styled.div`
  padding-bottom: 10px;
`;
