import {
  DocumentsWrapper,
  useConnectionBetweenTableAndURL as useDocumentsTableStateAndURL,
} from '@modules/document';

import { getCurrentProject } from '@modules/project/selectors';
import type { PrimaryRecordsRoutesParams } from '@modules/router/types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export function PrimaryRecordDocuments() {
  const {
    updateURLFromTableState: handleTableStateChanged,
    getTableStateFromURL: getDocumentsTableState,
  } = useDocumentsTableStateAndURL();
  const { recordId, organizationSlugifyName } =
    useParams<PrimaryRecordsRoutesParams>();
  const currentProjectId = useSelector(
    getCurrentProject as (state: any) => { _id: string },
  )._id;

  return (
    <DocumentsWrapper
      initialState={getDocumentsTableState()}
      partyId={recordId}
      projectId={currentProjectId}
      organizationSlugifyName={organizationSlugifyName!}
      onStateChanged={handleTableStateChanged}
    />
  );
}
