import { recordEmptyUndefinedToNull } from '@trustlayer/common';
import { Form, Modal } from 'antd';
import { ContactForm, type ContactFormValues } from '../ContactForm';
import { useUpdateContact } from './hooks/useUpdateContact';

type UpdateContactModalProps = {
  primaryRecordId: string;
  contactToUpdate: ContactFormValues;
  onCompleted: () => void;
  onClose: () => void;
};

export const UpdateContactModal = ({
  primaryRecordId,
  contactToUpdate,
  onCompleted,
  onClose,
}: UpdateContactModalProps) => {
  const { updateContact, loading } = useUpdateContact({
    primaryRecordId,
  });
  const [form] = Form.useForm();

  const handleCreateContact = async () => {
    const isFormValid = await form.validateFields();

    if (!isFormValid) {
      return;
    }

    const updatedContact = form.getFieldsValue();

    updateContact({
      contact: recordEmptyUndefinedToNull({
        ...contactToUpdate,
        ...updatedContact,
      }),
      onCompleted: onCompleted,
    });
  };

  return (
    <Modal
      open
      title="Edit contact"
      okText="Update contact"
      onOk={handleCreateContact}
      confirmLoading={loading}
      onCancel={() => {
        onClose?.();
      }}
    >
      <ContactForm form={form} initialValue={contactToUpdate} />
    </Modal>
  );
};
