export * from './ambest-values';
export * from './compliance-profile';
export * from './operators';
export * from './rule';
export const STATE_KEY = 'compliance-profile' as const;
export const namespace = 'COMPLIANCE_PROFILE' as const;

export enum MATCH_OPTIONS {
  matchRepeatedly = 'matchRepeatedly',
  matchOne = 'matchOnce',
}
