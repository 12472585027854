import { Button, Popover, Select } from 'antd';
import { type PropsWithChildren, useState } from 'react';
import styled from 'styled-components';

import { useContacts } from './hooks';

type ContactItem = { _id: string; name?: string; email?: string };

type AddParticipantsPopoverProps = PropsWithChildren<{
  isPopoverVisible: boolean;
  isPopoverLoading?: boolean;
  primaryRecordId: string;
  currentParticipants: ContactItem[];
  onApplyUpdates: (contactIds: string[]) => void;
  onClose?: () => void;
}>;

const getContactLabel = (contact: any) =>
  `${contact?.name} <${contact?.email}>`;

export const AddParticipantsPopover = ({
  children,
  isPopoverVisible = false,
  isPopoverLoading = false,
  currentParticipants,
  primaryRecordId,
  onApplyUpdates,
  onClose,
}: AddParticipantsPopoverProps) => {
  const initialSelectedContactIds = currentParticipants.map(
    (contact) => contact?._id,
  );

  const [selectedContactIds, setSelectedContactIds] = useState(
    initialSelectedContactIds,
  );

  const { contacts: contactsList, loading: loadingContacts } = useContacts({
    primaryRecordId,
    skipQuery: !isPopoverVisible,
  });

  const handleApplyUpdates = () => {
    onApplyUpdates(selectedContactIds);
  };

  const handleOnPopoverStatusChanged = (isVisible: boolean) => {
    setSelectedContactIds(isVisible ? initialSelectedContactIds : []);

    if (!isVisible) {
      onClose?.();
    }
  };

  return (
    <Popover
      open={isPopoverVisible}
      title="Edit contacts"
      placement="bottom"
      trigger="click"
      onOpenChange={handleOnPopoverStatusChanged}
      content={
        <StyledAddParticipantsPopover>
          <p>
            Newly added participants will receive future messages from this
            conversation.
          </p>
          <StyledSelect
            placeholder="Select contacts..."
            mode="multiple"
            value={loadingContacts ? [] : selectedContactIds}
            onChange={(values) => setSelectedContactIds(values as string[])}
            loading={loadingContacts || isPopoverLoading}
            disabled={loadingContacts || isPopoverLoading}
          >
            {contactsList
              .filter((contact) => contact?.email)
              .map((contact) => (
                <Select.Option key={contact?._id} value={contact?._id}>
                  {getContactLabel(contact)}
                </Select.Option>
              ))}
          </StyledSelect>
          <Button
            type="primary"
            onClick={handleApplyUpdates}
            disabled={!selectedContactIds.length}
            loading={isPopoverLoading}
          >
            Update
          </Button>
        </StyledAddParticipantsPopover>
      }
    >
      {children}
    </Popover>
  );
};

const StyledAddParticipantsPopover = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 15px;
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;
