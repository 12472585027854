import styled from 'styled-components';

import { Compliance } from '@common/icons';

type ComplianceStatsProps = {
  compliantCount: number;
  totalCount: number;
};

export const ComplianceStats = ({
  compliantCount,
  totalCount,
}: ComplianceStatsProps) => {
  const type = compliantCount === totalCount ? 'good' : 'progress';

  return (
    <StyledComplianceStats>
      <Compliance className="icon" type={compliantCount === 0 ? 'bad' : type} />
      <span>{`${compliantCount}/${totalCount}`}</span>
    </StyledComplianceStats>
  );
};

const StyledComplianceStats = styled.section`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.mineShaft};

  .icon {
    width: 20px;
  }
`;
