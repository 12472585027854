import { PlusOutlined } from '@ant-design/icons';
import {
  type ReportEntity as ViewEntity,
  ReportType as ViewType,
} from '@graphql/types/graphql';
import { Button, Modal, Skeleton, message } from 'antd';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ActionsMenuWithButtons } from '@common/components/ActionsMenuWithButtons';
import type { SelectedFilter } from '@common/components/DateFilterSelector';
import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import {
  type CustomSelectionChangedEvent,
  FilterButton,
  type IdFilter,
  type NumberFilter,
  type SetFilter,
  SortButton,
  type SortDirection,
  type SortingItems,
  type TextFilter,
} from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';
import { getAntdIconOutlinedByName } from '@common/utils/get-antd-icon-by-name';
import { RecordsTable } from '@modules/primary-objects';
import type { RecordsTableRef } from '@modules/primary-objects/containers/RecordsTable';
import { FILTERABLE_COLUMN_FIELDS } from '@modules/primary-objects/containers/RecordsTable/constants';
import {
  AUTOMATIONS_MODAL_TYPES,
  type ActionType,
  AutomationsActionModal,
  CreatePrimaryRecordModal,
  type CreatePrimaryRecordModalState,
  PRIMARY_RECORDS_ACTION_TYPES,
  getActionsMenuItems,
  useDeletePrimaryRecordsMutation,
} from '@modules/primary-records';
import type { LegacyPartiesReportParams } from '@modules/report/types';
import { DEFAULT_VIEWS, ViewsSelector } from '@modules/view';
import styled from 'styled-components';
import { usePrimaryObjectQuery } from './hooks/usePrimaryObjectQuery';
import { mapLegacyPartiesReportParamsToTableParams } from './utils/mapLegacyPartiesReportParamsToTableParams';
import { mapTableParamsToLegacyPartiesReportParams } from './utils/mapTableParamsToLegacyPartiesReportParams';

export type PrimaryObjectRecordsTableState = {
  filters: TableFiltersModel;
  sorting: {
    columnId?: string;
    direction?: SortDirection;
  };
  pagination: {
    currentPage?: number;
    pageSize?: number;
  };
  viewId?: string;
};

export type TableFiltersModel = {
  [FILTERABLE_COLUMN_FIELDS.name]?: TextFilter;
  [FILTERABLE_COLUMN_FIELDS.tags]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.activeDocuments]?: NumberFilter;
  [FILTERABLE_COLUMN_FIELDS.reviewStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.flagsSeverity]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.types]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.contextRecords]?: IdFilter;
  [FILTERABLE_COLUMN_FIELDS.waiversStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.overridesStatus]?: SetFilter;
  [FILTERABLE_COLUMN_FIELDS.nextComplianceExpiration]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.lastActivity]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.lastRequest]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.messageFailures]?: SelectedFilter<number>;
  [FILTERABLE_COLUMN_FIELDS.contactEmailStatus]?: SetFilter;
};

const filterInitialState = {};

const sortingInitialState = {
  columnId: 'createdAt',
  direction: 'desc' as const,
};

const paginationInitialState = {
  pageSize: 12,
  currentPage: 0,
};

const DEFAULT_TABLE_STATE = {
  filters: filterInitialState,
  sorting: sortingInitialState,
  pagination: paginationInitialState,
  viewId: undefined,
};

const initState = (
  initialState?: PrimaryObjectRecordsTableState,
): PrimaryObjectRecordsTableState => {
  if (!initialState) {
    return DEFAULT_TABLE_STATE;
  }

  if (isEmpty(initialState.filters)) {
    return DEFAULT_TABLE_STATE;
  }

  return {
    ...initialState,
    filters: initialState.filters,
  };
};

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedPrimaryRecordsIds: string[];
  selectedPrimaryRecordId?: string;
  selectedRowsData: any[];
};

const createRecordModalInitialState = {
  visible: false,
  primaryObjectId: '',
  primaryObjectName: '',
};

export function PrimaryObject() {
  const { objectSlug } = useParams<{ objectSlug: string }>();
  const tableRef = useRef<RecordsTableRef>(null);

  const [tableState, setTableState] =
    useSetState<PrimaryObjectRecordsTableState>(initState());

  const [visibleColumns, setVisibleColumns] = useState<SortingItems>([]);
  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedPrimaryRecordsIds: [],
    selectedPrimaryRecordId: undefined,
    selectedRowsData: [],
  });

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
    activeFilters: 0,
  });

  // MODALS STATES
  const [automationsModalState, setAutomationsModalState] = useSetState<{
    visible: boolean;
    type: keyof typeof AUTOMATIONS_MODAL_TYPES;
    selectedElementsIds?: string[];
  }>({
    visible: false,
    type: AUTOMATIONS_MODAL_TYPES.enable,
    selectedElementsIds: [],
  });

  const [createPrimaryRecordModalState, setCreatePrimaryRecordModalState] =
    useSetState<CreatePrimaryRecordModalState>(createRecordModalInitialState);

  // QUERIES AND MUTATIONS
  const { primaryObjectData, isPrimaryObjectLoading } =
    usePrimaryObjectQuery(objectSlug);

  const { deletePrimaryRecords, isDeletingPrimaryRecords } =
    useDeletePrimaryRecordsMutation();

  const viewParams = useMemo(() => {
    return mapTableParamsToLegacyPartiesReportParams({
      filters: tableState.filters,
      sorting: tableState.sorting,
    });
  }, [tableState.filters, tableState.sorting]);

  const handleCreatePrimaryRecord = () => {
    if (!primaryObjectData) return;

    setCreatePrimaryRecordModalState({
      visible: true,
      primaryObjectId: primaryObjectData._id,
      primaryObjectName: primaryObjectData.name,
    });
  };

  const handleOnFiltersChanged = (params: {
    activeFiltersCount: number;
    activeFilters: TableFiltersModel;
  }) => {
    setCounters({
      activeFilters: params.activeFiltersCount,
    });

    setTableState({
      filters: params.activeFilters,
    });
  };

  const handleOnSelectionChanged = (params: CustomSelectionChangedEvent) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedPrimaryRecordsIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleOnPaginationChanged = (params: any) => {
    setTableState({
      pagination: params,
    });
  };

  const handleOnSortChanged = (sorting: {
    columnId?: string;
    direction?: SortDirection;
  }) => {
    setTableState({
      sorting: sorting || sortingInitialState,
    });
  };

  const handleChangeView = useCallback(
    ({
      viewId,
      viewParams,
    }: {
      viewId?: string;
      viewParams: LegacyPartiesReportParams;
    }) => {
      const { filters, sorting } = mapLegacyPartiesReportParamsToTableParams(
        viewParams || {},
      );

      setTableState({ viewId, filters, sorting });

      tableRef?.current?.setFilters(filters);
      tableRef?.current?.setSorting(
        sorting.columnId || sortingInitialState.columnId,
        sorting.direction || sortingInitialState.direction,
      );
    },
    [setTableState],
  );

  const handleDeleteView = () => {
    setTableState({
      viewId: undefined,
      sorting: sortingInitialState,
      filters: filterInitialState,
    });

    tableRef?.current?.setFilters(filterInitialState);
  };

  const handleRecordActionClick = (code: ActionType, recordId: string) => {
    switch (code) {
      case PRIMARY_RECORDS_ACTION_TYPES.enableAutomation:
        setAutomationsModalState({
          visible: true,
          type: AUTOMATIONS_MODAL_TYPES.enable,
          selectedElementsIds: [recordId],
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.disableAutomation:
        setAutomationsModalState({
          visible: true,
          type: AUTOMATIONS_MODAL_TYPES.disable,
          selectedElementsIds: [recordId],
        });
        break;
      case 'archive':
        //TODO: open modal
        break;
      case 'delete':
        Modal.confirm({
          okButtonProps: {
            danger: true,
            loading: isDeletingPrimaryRecords,
          },
          type: 'warning',
          title: `Are you sure you want to delete this primary record ?`,
          onOk: async () => {
            try {
              await deletePrimaryRecords({
                variables: {
                  ids: [recordId],
                },
              });
              tableRef.current?.refreshTable();

              message.success(
                'Your primary record have been successfully deleted',
              );
            } catch (error) {
              message.error('An error occurred while deleting the records'); //TODO: handle multiple elements label
            }
          },
        });
        break;
      default:
        break;
    }
  };

  const handleBulkActionClick = (
    code: ActionType,
    options?: Record<string, any>,
  ) => {
    switch (code) {
      case PRIMARY_RECORDS_ACTION_TYPES.enableAutomation:
        setAutomationsModalState({
          visible: true,
          type: AUTOMATIONS_MODAL_TYPES.enable,
          selectedElementsIds: selection.selectedPrimaryRecordsIds,
        });
        break;
      case PRIMARY_RECORDS_ACTION_TYPES.disableAutomation:
        setAutomationsModalState({
          visible: true,
          type: AUTOMATIONS_MODAL_TYPES.disable,
          selectedElementsIds: selection.selectedPrimaryRecordsIds,
        });
        break;
      case 'archive':
        //TODO: open modal
        break;
      case 'delete':
        Modal.confirm({
          okButtonProps: {
            danger: true,
            loading: isDeletingPrimaryRecords,
          },
          type: 'warning',
          title: `Are you sure you want to delete ${counters.selectedRows} primary records ?`,
          onOk: async () => {
            try {
              const {
                data: { deletePrimaryRecords: isAsyncOperation },
              } = await deletePrimaryRecords({
                variables: {
                  ids: selection.selectedPrimaryRecordsIds,
                },
              });
              tableRef.current?.refreshTable();

              if (isAsyncOperation) {
                message.success(
                  `${selection.selectedPrimaryRecordsIds.length} primary records scheduled for deletion`,
                );
                return;
              }

              message.success(
                'Your primary records have been successfully deleted',
              );
            } catch (error) {
              message.error('An error occurred while deleting the records'); //TODO: handle multiple elements label
            }
          },
        });
        break;
      default:
        break;
    }
  };

  const bulkActionsMenuItems = useMemo(() => getActionsMenuItems({}), []);

  return (
    <StyledWrapper>
      <CreatePrimaryRecordModal
        modalState={createPrimaryRecordModalState}
        onSuccess={() => {
          tableRef.current?.refreshTable();
          setCreatePrimaryRecordModalState(createRecordModalInitialState);
        }}
        onClose={() => {
          setCreatePrimaryRecordModalState(createRecordModalInitialState);
        }}
      />
      <AutomationsActionModal
        type={automationsModalState.type}
        visible={automationsModalState.visible}
        // activeFilters={bulkActionFilterQuery} implement this when we have filters ready for the select all
        selectedElementsIds={automationsModalState.selectedElementsIds}
        onSuccess={() => {
          tableRef.current?.resetSelection();
          setAutomationsModalState({ visible: false, selectedElementsIds: [] });
        }}
        onClose={() =>
          setAutomationsModalState({ visible: false, selectedElementsIds: [] })
        }
      />
      <TableHeader>
        {isPrimaryObjectLoading ? (
          <PageTitle.Skeleton />
        ) : (
          <PageTitle
            title={primaryObjectData?.pluralName}
            icon={getAntdIconOutlinedByName(primaryObjectData?.icon)}
          />
        )}

        <TableHeader.TopRightActions>
          {isPrimaryObjectLoading ? (
            <Skeleton.Input style={{ width: 30 }} active />
          ) : (
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreatePrimaryRecord}
            >
              New {primaryObjectData?.name}
            </Button>
          )}
        </TableHeader.TopRightActions>
        {areBulkActionsButtonsVisible ? (
          <TableHeader.BottomLeftActions>
            <StyledBulkActions>
              <SelectionDropdown
                selectedEntitiesCount={counters.selectedRows}
                totalCount={counters.totalRows}
                totalVisible={counters.visibleRows}
                areAllSelected={false} //! implement this when we have filters ready for the select all
              />
              <ActionsMenuWithButtons
                items={bulkActionsMenuItems}
                onClick={handleBulkActionClick}
              />
            </StyledBulkActions>
          </TableHeader.BottomLeftActions>
        ) : (
          <>
            <TableHeader.BottomLeftActions>
              <StyledSearchInput
                //? implement `useConnectionBetweenTableAndURL` hook for primary objects for retriving the initial
                //? state from the url
                // defaultValue={value from url}
                onSearch={(value) => {
                  tableRef.current?.setFilters({
                    name: {
                      filterType: 'text',
                      filter: value,
                      type: 'contains',
                    },
                  });
                }}
              />

              <ViewsSelector
                type={ViewType.Parties}
                defaultView={DEFAULT_VIEWS.primaryObject as ViewEntity}
                viewParams={viewParams}
                viewId={tableState.viewId}
                onCreated={handleChangeView}
                onDeleted={handleDeleteView}
                onReset={handleDeleteView}
                onSelectChanged={handleChangeView}
              />
            </TableHeader.BottomLeftActions>
            <TableHeader.BottomRightActions>
              <FilterButton
                activeFiltersCount={counters.activeFilters}
                onClick={() => {
                  tableRef?.current?.toggleFiltersPanel();
                }}
              />
              <SortButton
                items={visibleColumns}
                initialValues={tableState.sorting}
                onChange={(sort) => {
                  tableRef.current!.setSorting(sort.columnId, sort.direction);
                }}
                onClick={() => {
                  const columns = tableRef?.current
                    ?.getSortableColumns()
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1));
                  setVisibleColumns(columns || []);
                }}
              />
            </TableHeader.BottomRightActions>
          </>
        )}
      </TableHeader>
      <StyledTable>
        <RecordsTable
          ref={tableRef}
          primaryObjectSlug={objectSlug!}
          onSelectionChanged={handleOnSelectionChanged}
          onFilterChanged={handleOnFiltersChanged}
          onSortChanged={handleOnSortChanged}
          onPaginationChanged={handleOnPaginationChanged}
          onRowActionClick={handleRecordActionClick}
          tableState={tableState}
        />
      </StyledTable>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledSearchInput = styled(SearchInput)`
  width: 280px;
`;

const StyledBulkActions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;
