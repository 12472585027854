import {
  ClockCircleOutlined,
  PlayCircleOutlined,
  TagsOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';

import {
  AddRemoveTagsActionIcon,
  SendEmailActionIcon,
  TriggerIcon,
  WaitIcon,
} from '../components/Icons';

export const STEP_TYPES = {
  wait: 'wait',
  action: 'action',
} as const;

export const STEP_JOB_TYPES = {
  sendDocumentReminder: 'sendDocumentReminder',
  sendExpiringDocumentReminder: 'sendExpiringDocumentReminder',
  addTags: 'addTags',
  removeTags: 'removeTags',
} as const;

export const TRIGGER_TYPES = {
  start: 'start',
  wait: 'wait',
  anchor: 'anchor',
} as const;

export const FILTER_NAMES = {
  nextComplianceExpiration: 'nextComplianceExpiration',
  automationId: 'automationId',
  status: 'status',
} as const;

export const FILTER_NAME_LABEL_MAP = {
  [FILTER_NAMES.nextComplianceExpiration]: 'Next Expiration Date',
  [FILTER_NAMES.automationId]: '',
  [FILTER_NAMES.status]: '',
} as const;

export const FILTER_OPERATORS = {
  afterExactly: 'afterExactly',
  beforeExactly: 'beforeExactly',
  In: 'in',
  Equal: 'equal',
} as const;

/**
 * @note operators are part of "filters" which are implemented based on starting from the current point in time.
 * Since automation's nodes/UI starts considering a point in the future the operator and the label have the opposite meaning
 *
 * i.e. "afterExactly" operator has "before" label
 */
export const FILTER_OPERATOR_LABELS_MAP = {
  [FILTER_OPERATORS.afterExactly]: 'before',
  [FILTER_OPERATORS.beforeExactly]: 'after',
  [FILTER_OPERATORS.In]: '',
  [FILTER_OPERATORS.Equal]: 'equals to',
} as const;

export const AUTOMATION_CODES = {
  documentReminders: 'documentReminders',
  expiringDocumentReminders: 'expiringDocumentReminders',
  exit: 'exit',
} as const;

export const TRIGGER_EVENTS = {
  dayStarted: 'day:started',
  documentRemindersEnabled: 'document-reminders:enabled',
  automationExit: 'automation:exit',
} as const;

export type AutomationCode =
  (typeof AUTOMATION_CODES)[keyof typeof AUTOMATION_CODES];

export type FilterName = keyof typeof FILTER_NAMES;
export type FilterOperator =
  (typeof FILTER_OPERATORS)[keyof typeof FILTER_OPERATORS];

export const VALIDATION_ERROR_MESSAGES = {
  emptyNodeType: 'Please select an action from the list',
  emptyDuration: 'Duration cannot be empty',
  durationExceedStartTrigger:
    'Duration cannot exceed length of time until Next Expiration Date',
  durationExceedPrevWaitTrigger: 'Duration cannot exceed previous Wait item',
  durationFallBehindPrevWaitTrigger:
    'Duration cannot fall behind previous Wait item',
  emptyEmailTemplate:
    'Please select an email template to save this automation.',
  emptyTagsInput: 'Please select at least a tag to save this automation.',
  emptyActionType: 'Please select an action type.',
};

export const DEAFULT_EMAIL_TEMPLATE_ID_PLACEHOLDER = 'default';

export const STEPS = {
  ADD_TAGS: {
    title: 'Add tags',
    IconComponent: TagsOutlined,
    AdvancedIconComponent: AddRemoveTagsActionIcon,
  },
  REMOVE_TAGS: {
    title: 'Remove tags',
    IconComponent: TagsOutlined,
    AdvancedIconComponent: AddRemoveTagsActionIcon,
  },
  WAIT: {
    title: 'Wait',
    IconComponent: ClockCircleOutlined,
    AdvancedIconComponent: WaitIcon,
  },
  SEND_EMAIL: {
    title: 'Send email',
    IconComponent: PlayCircleOutlined,
    AdvancedIconComponent: SendEmailActionIcon,
  },
};

export const TRIGGERS = {
  START: {
    title: 'Start',
    IconComponent: ThunderboltOutlined,
    AdvancedIconComponent: TriggerIcon,
  },
  WAIT: {
    title: 'Wait',
    IconComponent: ClockCircleOutlined,
    AdvancedIconComponent: WaitIcon,
  },
  ANCHOR: {
    title: 'Anchor',
  },
};

export const AUTOMATION_STATUS_LABELS = {
  cancelled: 'interrupted',
  completed: 'completed',
};
