import { gql } from '@apollo/client';
import { useMutation } from '@graphql/hooks';
import type { DisableAutomationInPrimaryRecordsMutationVariables } from '@graphql/types/graphql';

// TODO: In this mutation in the future will be added the return type for handling bulk operations
const DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION = gql`
  mutation DisableAutomationInPrimaryRecords($data: AutomationInPrimaryRecordsInput!) {
    disableAutomationInPrimaryRecords(data: $data) {
      operation {
        code
        name
        status
        progress
      }
      errors {
        code
        message
      }
    }
  }
`;

type DisableAutomationArgs = {
  variables: DisableAutomationInPrimaryRecordsMutationVariables['data'];
};

export const useDisableAutomationMutation = () => {
  const [
    disableAutomationInPrimaryRecords,
    { loading: isDisablingAutomation },
  ] = useMutation(DISABLE_PRIMARY_RECORDS_AUTOMATION_MUTATION);

  const disableAutomation = ({ variables }: DisableAutomationArgs) => {
    return disableAutomationInPrimaryRecords({
      variables: { data: variables },
    });
  };

  return {
    disableAutomation,
    isDisablingAutomation,
  };
};
