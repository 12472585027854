import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';

import { PageTitle } from '@common/components/PageTitleWrapper';
import SearchInput from '@common/components/SearchInput';
import SelectionDropdown from '@common/components/SelectionDropdown';
import { LinkCell, Table, type TableRef } from '@common/components/Table';
import { TableHeader } from '@common/components/TableHeader';
import useSetState from '@common/hooks/useSetState';

import { ComplianceStats } from '../../components/ComplianceStats';

import type { ContextRecord, RequestRecord } from '@graphql/types/graphql';
import { ContextRecordCell } from './components/ContextRecordCell';
import { COLUMN_FIELDS } from './constants';
import { RequestCreateModal } from './containers/RequestCreateModal';
import { useRequests } from './hooks';

type SelectionState = {
  isSelectAllChecked: boolean;
  selectedRequestIds: string[];
  selectedRowsData: any[];
};

export const RequestsTable = ({ recordId }: { recordId: string }) => {
  const tableRef = useRef<TableRef>(null);

  const [inputSearchValue, setInputSearchValue] = useState('');
  const [isRequestCreateModalVisible, setIsRequestCreateModalVisible] =
    useState(false);

  const [areBulkActionsButtonsVisible, setAreBulkActionsButtonsVisible] =
    useState(false);

  const [counters, setCounters] = useSetState({
    totalRows: 0,
    selectedRows: 0,
    visibleRows: 0,
  });

  const [selection, setSelection] = useSetState<SelectionState>({
    isSelectAllChecked: false,
    selectedRequestIds: [],
    selectedRowsData: [],
  });

  const { getRequestsList, deleteRequests } = useRequests({
    primaryRecordId: recordId,
  });

  const columns = useMemo(() => {
    return [
      {
        headerName: 'Request Name',
        field: COLUMN_FIELDS.name,
        filter: 'agTextColumnFilter',
        lockPosition: 'left' as const,
        pinned: 'left' as const,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        minWidth: 200,
        sortable: false,
        cellRenderer: ({ data }: { data: RequestRecord }) => (
          <LinkCell to={`${data._id}`}>{data.name}</LinkCell>
        ),
      },
      {
        headerName: 'Related',
        field: COLUMN_FIELDS.related,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ data }: { data: RequestRecord }) => data?.contextRecord,
        cellRenderer: ({ value }: { value: ContextRecord }) => (
          <ContextRecordCell name={value?.name} />
        ),
      },
      {
        headerName: 'Compliance',
        field: COLUMN_FIELDS.compliance,
        minWidth: 200,
        sortable: false,
        valueGetter: ({ data }: { data: any }) => data?.complianceStats,
        cellRenderer: ({ value }: any) => (
          <ComplianceStats
            totalCount={value.totalSubjects}
            compliantCount={value.compliantSubjects}
          />
        ),
      },
    ];
  }, []);

  const handleSelectionChange = (params: any) => {
    setAreBulkActionsButtonsVisible(
      params.isSelectAllChecked || params.selectedIds.length > 0,
    );

    setSelection({
      isSelectAllChecked: params.isSelectAllChecked,
      selectedRequestIds: params.selectedIds,
      selectedRowsData: params.selectedRowsData,
    });

    setCounters({
      totalRows: params.rowsTotalCount,
      selectedRows: params.isSelectAllChecked
        ? params.rowsTotalCount
        : params.selectedIds.length,
      visibleRows: params.visibleRowsCount,
    });
  };

  const handleSelectVisibleItems = () => {
    tableRef?.current?.selectVisibleRows();
  };

  const resetItemsTableSelection = () => {
    tableRef?.current?.resetSelection();
    setCounters({ selectedRows: 0 });
    setSelection({
      isSelectAllChecked: false,
      selectedRequestIds: [],
    });
  };

  const handleSelectAllItems = () => {
    tableRef?.current?.selectAllRows();
  };

  const handleDelete = () => {
    Modal.confirm({
      title: `Are you sure you want to delete?`,
      okText: 'Delete requests',
      okType: 'danger',
      onOk: () => {
        deleteRequests({
          requestsIds: selection.selectedRequestIds,
          nameFilterValue: inputSearchValue,
          onCompleted: () => {
            tableRef?.current?.refreshTable();
            resetItemsTableSelection();
          },
        });
      },
    });
  };

  return (
    <>
      {isRequestCreateModalVisible && (
        <RequestCreateModal
          recordId={recordId}
          onClosed={() => {
            setIsRequestCreateModalVisible(false);
          }}
          onCompleted={() => {
            setIsRequestCreateModalVisible(false);
            tableRef?.current?.refreshTable();
          }}
        />
      )}
      <StyledWrapper>
        <TableHeader>
          <PageTitle title="Requests" />
          <div className="top-right-actions">
            <Button
              icon={<PlusOutlined />}
              onClick={() => {
                setIsRequestCreateModalVisible(true);
              }}
            >
              New request
            </Button>
          </div>

          <div className="bottom-left-actions">
            {areBulkActionsButtonsVisible ? (
              <StyledBulkActions>
                <SelectionDropdown
                  areAllSelected={selection.isSelectAllChecked}
                  selectedEntitiesCount={counters.selectedRows}
                  onSelectAll={handleSelectAllItems}
                  onSelectAllVisible={handleSelectVisibleItems}
                  onSelectNone={resetItemsTableSelection}
                  totalCount={counters.totalRows}
                  totalVisible={counters.visibleRows}
                />

                <Button icon={<DeleteOutlined />} onClick={handleDelete}>
                  Delete
                </Button>
              </StyledBulkActions>
            ) : (
              <StyledSearchInputWrapper>
                <SearchInput
                  onChange={(e) => setInputSearchValue(e.target.value)}
                  defaultValue={inputSearchValue}
                  onSearch={(value) => {
                    tableRef.current?.setFilters({
                      [COLUMN_FIELDS.name]: {
                        filterType: 'text',
                        filter: value,
                        type: 'contains',
                      },
                    });
                  }}
                />
              </StyledSearchInputWrapper>
            )}
          </div>
        </TableHeader>
        <StyledTable>
          <Table
            isSideBarDisbaled
            ref={tableRef}
            columnDefs={columns}
            getRowData={getRequestsList}
            onSelectionChanged={handleSelectionChange}
          />
        </StyledTable>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSearchInputWrapper = styled.div`
  width: 280px;
`;

const StyledTable = styled.section`
  padding: 24px;
  flex: 1;
`;

const StyledBulkActions = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
`;
