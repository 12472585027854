import { UserOutlined } from '@ant-design/icons';
import InfiniteScrollSelect from '@common/components/InfiniteScrollSelect';
import { useInputFocusOnMount } from '@common/hooks';
import { Form, type FormInstance, Input } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import {
  type Contact,
  useContactsEmailsForSearch,
} from '../hooks/useContactsEmailsForSearch';

export type CreatePrimaryRecordModalState = {
  visible: boolean;
  primaryObjectId: string;
  primaryObjectName: string;
};

type FormValues = {
  recordName: string | null;
  contactEmail: string | null;
  contactName: string | null;
  contactNumber: string | null;
  contactId: string | null;
};

export const ModalFormContent = ({
  formInstance,
}: { formInstance: FormInstance<FormValues> }) => {
  const [isUserSelected, setIsUserSelected] = useState(false);
  const inputRef = useInputFocusOnMount(null);

  const {
    contactsList,
    contactsListTotalCount,
    contactsLoading,
    contactsLoadingMore,
    filterContactsByEmail,
    fetchMoreContactsForSearch,
  } = useContactsEmailsForSearch();

  const handleOnSelect = (
    _value: any,
    option: { key: string; value: string; contactData: Contact },
  ) => {
    formInstance.setFieldsValue({
      contactName: option?.contactData?.contactPersonName,
      contactNumber: option?.contactData?.phone,
      contactId: option?.contactData?._id,
    });

    setIsUserSelected(true);
  };

  const handleOnClear = () => {
    formInstance.setFieldsValue({
      contactEmail: null,
      contactName: null,
      contactNumber: null,
      contactId: null,
    });
    setIsUserSelected(false);
  };

  const handleOnSearch = (searchedValue: string) => {
    filterContactsByEmail(searchedValue);
  };

  const handleOnChange = (value: string) => {
    if (!value && isUserSelected) {
      formInstance.setFieldsValue({
        contactEmail: null,
        contactName: null,
        contactNumber: null,
        contactId: null,
      });
    }

    setIsUserSelected(false);
  };

  return (
    <Form
      form={formInstance}
      layout="horizontal"
      name="createPrimaryRecordForm"
      labelCol={{
        sm: { span: 3 },
      }}
      wrapperCol={{
        sm: { span: 21 },
      }}
    >
      <Form.Item
        name="recordName"
        label="Name"
        rules={[{ required: true, message: 'Please enter a name' }]}
      >
        <Input
          data-cy="recordName"
          placeholder="Enter name..."
          ref={inputRef}
        />
      </Form.Item>

      <StyledContactFormGroup>
        <h3>Contact</h3>
        <Form.Item
          name="contactEmail"
          label="Email"
          rules={[
            {
              required: true,
              message: 'Please enter a valid email',
              type: 'email',
            },
          ]}
        >
          <InfiniteScrollSelect
            as="AutoComplete"
            allowClear
            showSearch
            isDataLoading={contactsLoading || contactsLoadingMore}
            placeholder="Enter contact email..."
            onSearch={handleOnSearch}
            // @ts-expect-error - Antd types accepts custom props but are not typed
            onSelect={handleOnSelect}
            onChange={handleOnChange}
            onClear={handleOnClear}
            notFoundContent={null}
            suffixIcon={null}
            hasMore={contactsListTotalCount > contactsList?.length}
            loadMore={fetchMoreContactsForSearch}
            options={contactsList?.map((option) => ({
              key: option._id,
              value: option.email,
              'data-cy': 'select_option',
              label: (
                <StyledOption>
                  <UserOutlined />
                  <span>{`${option.contactPersonName} - ${option.email}`}</span>
                </StyledOption>
              ),
              contactData: option,
            }))}
          />
        </Form.Item>
        <Form.Item name="contactName" label="Name">
          <Input
            data-cy="contactName"
            disabled={isUserSelected}
            type="text"
            placeholder="Enter contact name..."
          />
        </Form.Item>
        <Form.Item hidden={true} name="contactId" label="Id">
          <Input type="text" placeholder="Enter contact name..." />
        </Form.Item>
        {/*
            //? Add this when BE accepts the number
          <Form.Item name="contactNumber" label="Phone">
            <Input
              disabled={isUserSelected}
              type="text"
              placeholder="Enter contact number..."
            />
          </Form.Item> */}
      </StyledContactFormGroup>
    </Form>
  );
};

const StyledContactFormGroup = styled.div`
  background-color: #FAFAFA;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  padding: 16px;

  h3 {
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.subtitleText};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;

const StyledOption = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
