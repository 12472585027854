import { useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import { NonWaived } from '@trustlayer/common';

export const TOGGLE_REQUEST_RECORD_REQUIREMENT_WAIVER_OVERRIDE_MUTATION =
  graphql(`mutation ToggleRequestRecordRequirementWaiverOverride($data: ToggleRequestRecordRequirementsWaiverInput!) {
  toggleRequestRecordRequirementsWaiver(data: $data)
}
`);

type AddWaiverOverrideRequirementVariables = {
  variables: {
    requestId: string;
    requirement: Record<string, any>;
  };
  onCompleted?: () => void;
  onError?: (error: Error) => void;
};
type RemoveWaiverOverrideRequirementVariables = {
  variables: {
    requestId: string;
    code: string;
  };
  onCompleted?: () => void;
  onError?: (error: Error) => void;
};

export function useWaiveOverrideRequirement() {
  const [toggleWaiverOverrideRequirement, { loading }] = useMutation(
    TOGGLE_REQUEST_RECORD_REQUIREMENT_WAIVER_OVERRIDE_MUTATION,
  );

  function addWaiverOverrideRequirement({
    variables: { requestId, requirement },
    onCompleted,
    onError,
  }: AddWaiverOverrideRequirementVariables) {
    return toggleWaiverOverrideRequirement({
      variables: {
        data: {
          id: requestId!,
          requirements: [requirement],
        },
      },
      onCompleted,
      onError,
    });
  }

  function removeWaiverOverrideRequirement({
    variables: { requestId, code },
    onCompleted,
    onError,
  }: RemoveWaiverOverrideRequirementVariables) {
    return toggleWaiverOverrideRequirement({
      variables: {
        data: {
          id: requestId!,
          requirements: [
            {
              attributeCode: code,
              status: NonWaived,
            },
          ],
        },
      },
      onCompleted,
      onError,
    });
  }

  return {
    removeWaiverOverrideRequirement,
    addWaiverOverrideRequirement,
    loadingWaiverOverrideRequirement: loading,
  };
}
