import { Popover } from 'antd';
import pluralize from 'pluralize';
import styled from 'styled-components';

type RecordsActiveDocumentsProps = {
  activeDocuments: number;
  totalDocuments: number;
};

export const RecordsActiveDocuments = ({
  activeDocuments,
  totalDocuments,
}: RecordsActiveDocumentsProps) => {
  const totalDocumentsText = `${totalDocuments} ${pluralize(
    'document',
    totalDocuments,
  )}`;
  const activeDocumentsText = `${activeDocuments} ${pluralize(
    'document',
    activeDocuments,
  )}`;

  return (
    <StyledWrapper>
      {activeDocuments > 0 && (
        <Popover content={`${activeDocumentsText} to review`}>
          <span className="blue">{`${totalDocumentsText}`}</span>
        </Popover>
      )}

      {activeDocuments === 0 && totalDocuments > 0 && (
        <Popover content="No documents to review" mouseEnterDelay={2}>
          <span>{`${totalDocumentsText}`}</span>
        </Popover>
      )}

      {activeDocuments === 0 && totalDocuments === 0 && '-'}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  display: inline-flex;
  padding: 0 8px;
  align-items: center;
  height: 30px;
  gap: 8px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.black};

  .blue {
    color: ${({ theme }) => theme.colors.blue};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
