import { utc } from '@common/utils/date';
import styled from 'styled-components';

import ExpirationBadgePopover from '@common/components/Badges/components/ExpirationBadgePopover';
import { getExpirationStatus } from '@common/components/Badges/utils/badges-helpers';

type ExpirationProps = {
  effectiveDate: string;
  expirationDate: string;
};

const pickDateColor = (
  colors: any,
  status: { status: string | null; color: string },
) =>
  status?.color === 'iconGray' ? 'rgba(0, 0, 0, 0.85)' : colors[status?.color];

export const ExpirationDates = ({
  effectiveDate,
  expirationDate,
}: ExpirationProps) => {
  const hasEmptyDates = !(effectiveDate || expirationDate);
  const hasEffectiveDate = Boolean(effectiveDate);

  if (hasEmptyDates) return null;

  return (
    <StyledValidityContainer>
      {/* @ts-expect-error - it requires a props that is not mandatory  */}
      <ExpirationBadgePopover
        expirationDate={expirationDate}
        sizeIcon="Medium"
        defaultVisible
      />
      <StyledDatesContainer $status={getExpirationStatus(expirationDate)}>
        {hasEffectiveDate && (
          <>
            {utc(effectiveDate).format('MMM DD, YYYY')}
            <span>→</span>
          </>
        )}
        {utc(expirationDate).format('MMM DD, YYYY')}
      </StyledDatesContainer>
    </StyledValidityContainer>
  );
};

const StyledValidityContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDatesContainer = styled.div<{
  $status: { color: string; status: string | null };
}>`
  padding: 2px 7px;
  font-size: 12px;
  font-weight: 500;
  margin-left: -7px;
  color: ${(props) => pickDateColor(props.theme.colors, props.$status)};

  &:empty {
    display: none;
  }

  span {
    display: inline-block;
    margin: 0 6px;
  }
`;
