import FullWidthSpace from '@common/components/FullWidthSpace';
import {
  Alert,
  Button,
  Checkbox,
  Form,
  type FormInstance,
  Input,
  Upload,
} from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { useUploadAvatar, useValidateEmail } from './hooks';

const INVALID_EMAIL_MESSAGE = 'Please enter a valid email address';

export type Contact = {
  _id?: string;
  email?: string;
  contactPersonName?: string;
  companyName?: string;
  title?: string;
  phone?: string;
  fax?: string;
  avatar?: string;
  defaultRequestRecipient?: boolean;
  primary?: boolean;
};

type ContactFormProps = {
  initialValue?: Contact;
  form: FormInstance;
};

export const ContactForm = ({ initialValue, form }: ContactFormProps) => {
  const avatarImgUrl = Form.useWatch('avatar', form);
  const [isContactFound, setIsContactFound] = useState(false);
  const { uploadAvatar, loading } = useUploadAvatar();

  const { isEmailTaken } = useValidateEmail();

  const doesEmailExist = async (_: unknown, email = '') => {
    /**
     * @note the condition (email === initialValue?.email) avoids to check the email by the server
     * if the form is in "update" mode
     */
    if (!email || email === initialValue?.email) {
      return;
    }
    const res = await isEmailTaken(email);
    if (res) {
      setIsContactFound(true);
      throw new Error('');
    }
    return;
  };

  const isFormDisabled = isContactFound;

  // Check if in update mode
  const isUpdateMode = initialValue?._id !== undefined;

  return (
    <Form form={form} layout="vertical" initialValues={initialValue}>
      <Form.Item
        label="Email"
        name="email"
        validateTrigger="onBlur"
        rules={[
          { required: true, message: 'Please enter an email address' },
          { type: 'email', message: INVALID_EMAIL_MESSAGE },
          { validator: doesEmailExist },
        ]}
      >
        <Input disabled={isUpdateMode} placeholder="Enter contact email..." />
      </Form.Item>

      {
        // @note: this is a custom error message for the email field
        isContactFound && (
          <StyledAlert
            showIcon
            message={`A contact with the email ${form.getFieldValue(
              'email',
            )} has been found`}
            type="info"
          />
        )
      }

      {isFormDisabled}
      <Form.Item label="Name" name="contactPersonName">
        <Input placeholder="Enter contact name..." disabled={isFormDisabled} />
      </Form.Item>
      <FullWidthSpace direction="vertical" size="middle">
        <Form.Item label="Company" name="companyName">
          <Input
            placeholder="Enter contact company..."
            disabled={isFormDisabled}
          />
        </Form.Item>
        <Form.Item label="Title" name="title">
          <Input
            placeholder="Enter contact title..."
            disabled={isFormDisabled}
          />
        </Form.Item>
      </FullWidthSpace>

      <FullWidthSpace direction="vertical" size="middle">
        <Form.Item label="Phone" name="phone">
          <Input
            placeholder="Enter contact phone..."
            disabled={isFormDisabled}
          />
        </Form.Item>
        <Form.Item label="Fax" name="fax">
          <Input placeholder="Enter contact fax..." disabled={isFormDisabled} />
        </Form.Item>
      </FullWidthSpace>

      <Form.Item
        label="Avatar"
        name="avatar"
        getValueFromEvent={(e) => {
          if (e?.fileList && Array.isArray(e.fileList)) {
            return e.fileList[0].response ?? null;
          }

          return null;
        }}
      >
        <StyledUpload
          listType="picture"
          name="avatar"
          customRequest={async ({ file, onSuccess }) => {
            if (file instanceof File) {
              const url = await uploadAvatar(file);

              onSuccess?.(url);
            }
          }}
          onRemove={() => {
            form.setFieldValue('avatar', null);
          }}
          defaultFileList={
            initialValue?.avatar
              ? [
                  {
                    uid: '-1',
                    url: initialValue.avatar,
                    name: `avatar.${initialValue.avatar.split('.').pop()}`,
                    status: 'done',
                  },
                ]
              : undefined
          }
          maxCount={1}
          accept=".png,.jpg,.jpeg"
          disabled={isFormDisabled}
        >
          {!avatarImgUrl && (
            <StyledAvatarUploader>
              <div>
                <span>Custom avatar</span>
                <span className="subtitle">
                  Format: PNG or JPG, minimum 80px square or larger
                </span>
              </div>
              <Button loading={loading} disabled={isFormDisabled}>
                Upload
              </Button>
            </StyledAvatarUploader>
          )}
        </StyledUpload>
      </Form.Item>

      <StyledBorderedSection>
        <StyledCustomSection>
          <span className="subtitle">For __COMPANY_NAME__</span>
          <Form.Item name="defaultRequestRecipient" valuePropName="checked">
            <Checkbox disabled={isFormDisabled}>
              Use this contact for requests on __COMPANY_NAME__
            </Checkbox>
          </Form.Item>
          <Form.Item valuePropName="checked" name="primary">
            <Checkbox disabled={isFormDisabled}>
              Set as primary contact for __COMPANY_NAME__
            </Checkbox>
          </Form.Item>
        </StyledCustomSection>
      </StyledBorderedSection>
    </Form>
  );
};

const StyledAlert = styled(Alert)`
  margin-bottom: 16px;
`;

const StyledBorderedSection = styled.div`
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.lightGray};
  padding: 8px;
`;

const StyledCustomSection = styled.div`
  .subtitle {
    color: ${({ theme }) => theme.colors.subtitleText};
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

const StyledUpload = styled(Upload)`
  width: 100%;  

  .ant-upload  {
    width: 100%;
  }
`;

const StyledAvatarUploader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colors.mineShaft};

  .subtitle {
    display: block;
    color: ${({ theme }) => theme.colors.lightTextGray};
  }
`;
