export const PRIMARY_RECORDS_TABS = {
  Requests: {
    name: 'Requests',
    key: 'requests',
  },
  Activity: {
    name: 'Activity',
    key: 'activity',
  },
  Documents: {
    name: 'Documents',
    key: 'documents',
  },
  Attributes: {
    name: 'Attributes',
    key: 'attributes',
  },
  Contacts: {
    name: 'Contacts',
    key: 'contacts',
  },
  Messages: {
    name: 'Emails',
    key: 'messages',
  },
} as const;

export const PRIMARY_RECORDS_ACTION_TYPES = {
  enableAutomation: 'enableAutomation',
  disableAutomation: 'disableAutomation',
  archive: 'archive',
  delete: 'delete',
} as const;

export type ActionType =
  (typeof PRIMARY_RECORDS_ACTION_TYPES)[keyof typeof PRIMARY_RECORDS_ACTION_TYPES];
