import type {
  Attachment,
  ConversationMessage,
  ConversationMessageIssue,
} from '@graphql/types/graphql';

type ConversationEvent = {
  _id: string;
  type: ConversationMessage['type'];
  outputHtml: ConversationMessage['outputHtml'];
  request: ConversationMessage['request'];
  senderType: ConversationMessage['senderType'];
  status: ConversationMessage['status'];
  recipients: ConversationMessage['recipients'];
  createdAt: string;
  senderContactId: string;
  data: ConversationMessage['data'];
  attachments: Attachment[];
  issues: ConversationMessageIssue[];
  sendgridEvents: ConversationMessage['mostRecentUniqueSendgridEvents'];
};

const intlInstance = new Intl.DateTimeFormat('en-US', {
  dateStyle: 'full',
});

/**
 * @note The UI is showing the messages from the bottom to the top of the container,
 * in order to keep them sorted by creation date the array is reversing, and then grouped by date.
 */
export const getMessagesGruopByDateMap = (messages: any) => {
  const reversedMessages = [...messages].reverse();

  return reversedMessages.reduce((acc: any, message: any) => {
    const fullDate = intlInstance.format(new Date(message.createdAt));

    if (!acc[fullDate]) {
      return {
        ...acc,
        [fullDate]: [message],
      };
    }

    acc[fullDate].push(message);
    return acc;
  }, {});
};

export const getSenderData = (message: any) => {
  return {
    name:
      message.senderContact?.contactPersonName ||
      message.senderUser?.profile?.name,
    email: message.senderContact?.email || message.senderUser?.email,
    avatar:
      message.senderContact?.avatar || message.senderUser?.profile?.avatar,
  };
};

/**
 * @note used to align API query connection with ConversationEvent component props.
 * The ConversationEvent component should keep the backwards compatibility since it's used also in legacy code.
 */
export const normalizeMessagesList = (
  messages: ConversationMessage[],
): ConversationEvent[] => {
  return messages.map((message) => ({
    _id: message._id,
    type: message.type,
    outputHtml: message.outputHtml,
    request: message.request,
    senderType: message.senderType,
    status: message.status,
    recipients: message.recipients,
    createdAt: message.createdAt,
    senderContactId: message.senderContact?._id || '',
    data: message.data,
    attachments: message.attachments?.nodes || [],
    issues: message.issues?.nodes || [],
    sendgridEvents: message.mostRecentUniqueSendgridEvents || [],
  }));
};
