import { ReportType as ViewsTypeGql } from '@graphql/types/graphql';

export const DEFAULT_VIEWS = {
  documents: {
    _id: `default_${ViewsTypeGql.Documents}`,
    name: 'Active Documents',
    isPrivate: false,
    type: ViewsTypeGql.Documents,
    filters: {
      status: ['nonArchived'],
      sortField: 'createdAt',
      sortDirection: 'desc',
    },
  },
  primaryObject: {
    _id: `default_${ViewsTypeGql.Parties}`,
    name: 'All',
    isPrivate: false,
    type: ViewsTypeGql.Parties,
    filters: {
      sortField: 'createdAt',
      sortDirection: 'desc',
    },
  },
};
