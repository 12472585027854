import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import { FILTER_OPERATORS } from '@common/constants/filters';
import { useLazyQuery } from '@graphql/hooks';
import { graphql } from '@graphql/types';
import type { ComplianceProfilesQuery } from '@graphql/types/graphql';

type ComplianceProfile = {
  _id: string;
  name: string;
};

const DAFAULT_PAGE_SIZE = 4;

const DEFAULT_INPUT = {
  first: DAFAULT_PAGE_SIZE,
  sort: {
    name: -1,
  },
};

export const COMPLIANCE_PROFILES_QUERY = graphql(`
  query ComplianceProfiles($input: ComplianceProfilesInput) {
    complianceProfiles(input: $input) {
      nodes {
        _id
        name
      }
      totalCount
    }
  }
`);

export const useComplianceProfiles = () => {
  const [complianceProfiles, setComplianceProfiles] = useState<
    ComplianceProfile[]
  >([]);
  const [complianceProfilesTotalCount, setComplianceProfilesTotalCount] =
    useState(0);

  const [fetchComplianceProfile, { loading, fetchMore }] = useLazyQuery(
    COMPLIANCE_PROFILES_QUERY,
    {
      variables: {
        input: {
          ...DEFAULT_INPUT,
          offset: 0,
        },
      },
    },
  );

  const onCompleted = ({ complianceProfiles }: ComplianceProfilesQuery) => {
    setComplianceProfiles(complianceProfiles.nodes);
    setComplianceProfilesTotalCount(complianceProfiles.totalCount);
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const filterComplianceProfileByName = useMemo(
    () =>
      debounce((name: string) => {
        fetchComplianceProfile({
          variables: {
            input: {
              ...DEFAULT_INPUT,
              offset: 0,
              filter: {
                and: [
                  {
                    name: 'name',
                    operator: FILTER_OPERATORS.contains,
                    value: name,
                  },
                ],
              },
            },
          },
          onCompleted,
        });
      }, 500),
    [fetchComplianceProfile],
  );

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  useEffect(() => {
    fetchComplianceProfile({
      onCompleted,
    });
  }, [fetchComplianceProfile]);

  return {
    complianceProfiles,
    complianceProfilesTotalCount,
    loading,
    fetchMoreComplianceProfiles: () => {
      fetchMore({
        variables: {
          input: {
            ...DEFAULT_INPUT,
            offset: complianceProfiles.length,
          },
        },
      });
    },
    filterComplianceProfileByName,
  };
};
