import { useCallback } from 'react';

import type { GetRowData } from '@common/components/Table';
import { useCustomLazyQuery, useMutation } from '@graphql/hooks';
import { graphql } from '@graphql/types';

import type { AttributeToUpdate } from '../constants';

type UpdateAttributeParams = {
  attribute: AttributeToUpdate;
  onCompleted: (param: boolean) => void;
};

export const RECORD_ATTRIBUTES_QUERY = graphql(`
  query PrimaryRecordAttributes(
    $primaryRecordInput: PrimaryRecordInput!
    $input: RecordAttributesInput
  ) {
    primaryRecord(input: $primaryRecordInput) {
      _id
      attributes(input: $input) {
        nodes {
          ... on SystemAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
            }
            options {
              _id
              value
            }
          }
          ... on CustomAttribute {
            _id
            name
            value
            type
            selectedOptions {
              _id
              value
            }
            options {
              _id
              value
            }
          }
        }
        totalCount
      }
    }
  }
`);

const UPDATE_ATTRIBUTES_MUTATION = graphql(`
  mutation UpdateAttribute($data: UpdateRecordAttributesInput!) {
    updateRecordAttributes(data: $data)
  }
`);

export const useRecordAttributes = ({
  primaryRecordId,
}: {
  primaryRecordId: string;
}) => {
  const getAttributesQuery = useCustomLazyQuery(RECORD_ATTRIBUTES_QUERY);
  const [updateAttributeMutation] = useMutation(UPDATE_ATTRIBUTES_MUTATION);

  const getAttributes: GetRowData = useCallback(async () => {
    const { data } = await getAttributesQuery({
      variables: {
        primaryRecordInput: { id: primaryRecordId },
      },
    });

    return {
      rowData: data?.primaryRecord?.attributes.nodes || [],
      rowCount: data?.primaryRecord?.attributes.totalCount || 0,
    };
  }, [primaryRecordId, getAttributesQuery]);

  const updateAttribute = useCallback(
    ({ attribute, onCompleted }: UpdateAttributeParams) => {
      return updateAttributeMutation({
        variables: {
          data: {
            id: primaryRecordId,
            reference: 'primary',
            attributes: [attribute],
          },
        },
        onCompleted: (data) => {
          onCompleted?.(data.updateRecordAttributes);
        },
      });
    },
    [primaryRecordId, updateAttributeMutation],
  );

  return {
    updateAttribute,
    getAttributes,
  };
};
