import qs from 'query-string';
import * as R from 'ramda';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { getPreferredSearchParams } from '@modules/system-settings/selectors';

const useEntityQueryString = () => {
  const { search } = useLocation();
  const preferredSearchParams = useSelector(getPreferredSearchParams);
  const searchParams = qs.parse(search);

  // biome-ignore lint/correctness/useExhaustiveDependencies: Legacy
  const getQsForEntitiyTypeList = useCallback(
    R.compose(
      (obj) => qs.stringify(obj),
      R.assoc('type', R.__, R.omit(['report', 'page'], searchParams)),
      R.prop('_id'),
    ),
    [searchParams],
  );

  const getQsForEntityList = useCallback(
    (entity) =>
      R.compose(
        (obj) => qs.stringify(obj),
        R.merge(R.__, preferredSearchParams[entity]),
        R.omit([
          'type',
          'page',
          'sortDirection',
          'sortField',
          'size',
          'view',
          'report',
          'entity',
        ]),
      )(searchParams),
    [searchParams, preferredSearchParams],
  );

  const getQsForEntityInParty = useCallback(
    (entity) => qs.stringify(preferredSearchParams[`party-${entity}`]),
    [preferredSearchParams],
  );

  return { getQsForEntityList, getQsForEntitiyTypeList, getQsForEntityInParty };
};

export default useEntityQueryString;
